import React, { useEffect, useRef, useState } from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon, MDBInput,
  MDBModal, MDBModalBody,
  MDBModalContent,
  MDBModalDialog, MDBModalFooter,
  MDBModalHeader, MDBModalTitle,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import { formatName, SOCIAL_ACCOUNTS } from '../shared/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from '../shared/store/user.reducer';
import { ApiClient } from '../shared/api_client';
import './dashboard.css';
import { toggleMainLoader } from '../shared/store/app.reducer';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const Dashboard = () => {

  const userId = useSelector(getUserId);
  const [clients, setClients]: [any, any] = useState([]);
  const [invitationEmail, setInvitationEmail]: [any, any] = useState('');
  const [invitationFirstName, setInvitationFirstName]: [any, any] = useState('');
  const [invitationLastName, setInvitationLastName]: [any, any] = useState('');
  const [invitationModal, setInvitationModal]: any = useState(false);
  const [pendingRequest, setPendingRequest]: any = useState(false);

  const {t} = useTranslation();

  const invitationForm: any = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchClientsData()
      .catch(error => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);


  const fetchClientsData = async () => {
    if (userId) {
      try {
        dispatch(toggleMainLoader({
          isVisible: true
        }));

        const clientsResponse = await ApiClient.getManagedClients(userId);
        let clientsData = clientsResponse.data;

        console.log(clientsData);
        setClients(clientsData);

        dispatch(toggleMainLoader({
          isVisible: false
        }));

        const clientsPostsPromises: any = [];
        clientsData.forEach((client: any) => {
          clientsPostsPromises.push(ApiClient.getScheduledMessages(client.user, startOfPreviousWeek()));
        });

        let clientPosts = await Promise.allSettled(clientsPostsPromises);
        clientPosts = clientPosts
          .filter(postResponse => postResponse.status === 'fulfilled') // Filter only the fulfilled responses
          .map((postResponse: any) => postResponse.value?.data?.body); // Extract the post data

        clientsData = clientsData.map((client: any, index: number) => ({
          ...client,
          posts: clientPosts[index] || [], // Add posts to the client, default to an empty array if no posts
        }));

        setClients(clientsData);

      } catch (e) {
        console.error(e);
      }

      dispatch(toggleMainLoader({
        isVisible: false
      }));
    }
  }

  const startOfPreviousWeek = () => {
    const now = new Date();

    // Get the current day of the week (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
    const currentDay = now.getDay();

    // Calculate how many days ago was the previous Monday
    // If today is Monday, we go back 7 days (last week)
    const daysToLastMonday = currentDay === 0 ? 6 + 7 : currentDay - 1 + 7;

    // Subtract the number of days to get to the previous Monday
    now.setDate(now.getDate() - daysToLastMonday);

    // Set the time to midnight
    now.setHours(0, 0, 0, 0);

    // Get the timestamp (in milliseconds since the Unix epoch)
    return now.getTime() / 1000;
  }

  const renderUser = (clientsData: any) => {
    const renderer: any = [];
    clientsData.forEach((client: any) => {
      const providersRender = client.socialAccounts?.map((account: any) => {
        const icon: any = SOCIAL_ACCOUNTS[account.provider].icon;
        const iconColor = SOCIAL_ACCOUNTS[account.provider].iconColor;
        return <MDBIcon className="me-1 fs-5" key={`provider-${account.provider}`} fab icon={icon}
                        style={{color: iconColor}}/>
      });
      renderer.push(
        <tr key={client.user}>
          <td>
            <div className="d-flex">
              {formatName(client.firstName, client.lastName)}
            </div>
          </td>
          <td>
            {client.email}
          </td>
          <td>
            {providersRender}
          </td>
          <td>
            {client?.posts?.length || '-'} / 2
          </td>
        </tr>
      );
    })
    return renderer;
  }

  const inviteClient = async (e: any) => {
    e.preventDefault();
    setPendingRequest(true);
    ApiClient.inviteClient({
      email: invitationEmail,
      firstName: invitationFirstName,
      lastName: invitationLastName,
      creator: userId
    }).then(async () => {
      await fetchClientsData();
      setInvitationModal(false);
    }).catch(exception => {
      console.log(exception);
      toast.error(t('shared.errors.unexpected'));
    }).finally(() => {
      setPendingRequest(false);
    })
  }

  const renderInvitationModal = () => {
    return (
      <>
        <form ref={invitationForm} onSubmit={inviteClient}>
          <MDBModal tabIndex="-1" show={invitationModal} setShow={setInvitationModal}>
            <MDBModalDialog centered>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    Client invitation
                  </MDBModalTitle>
                  <MDBBtn className="btn-close" color="none" onClick={() => {
                  }}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBInput className="mb-3" required label="First name" value={invitationFirstName} onChange={(e) => {
                    setInvitationFirstName(e.target.value);
                  }}/>
                  <MDBInput className="mb-3" required label="Last name" value={invitationLastName} onChange={(e) => {
                    setInvitationLastName(e.target.value);
                  }}/>
                  <MDBInput label="Client email" required type="email" value={invitationEmail} onChange={(e) => {
                    setInvitationEmail(e.target.value);
                  }}/>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn className="w-50 m-0" color="secondary" onClick={() => setInvitationModal(false)}>
                    {t('shared.cancel')}
                  </MDBBtn>
                  <MDBBtn className="btn-with-loader w-50 m-0" color="primary" type="submit" disabled={pendingRequest}>
                    {pendingRequest ? 'Processing' : 'Invite'}
                    {pendingRequest &&
                      <div className="loader-icon fs-5">
                        <MDBIcon fas icon="circle-notch"/>
                      </div>
                    }
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </form>
      </>
    )
  }

  return (
    <MDBContainer>
      {renderInvitationModal()}
      <MDBRow id="dashboard" className="px-3 mt-4">
        <MDBCol>
          <MDBBtn className="fw-bold" onClick={() => setInvitationModal(!invitationModal)}>
            <MDBIcon fas icon="plus" className="me-2"/>
            Add account
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow id="dashboard" className="px-3 py-4 m-0">
        <MDBTable>
          <MDBTableHead>
            <tr>
              <th scope="col">Account</th>
              <th scope="col">Email</th>
              <th scope="col">Social Accounts</th>
              <th scope="col">Posts</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {clients?.length > 0 && renderUser(clients)}
          </MDBTableBody>
        </MDBTable>
      </MDBRow>
    </MDBContainer>
  );
};

export default Dashboard;
import {createSlice} from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    mainLoader: {
      isVisible: false
    }
  },
  reducers: {
    toggleMainLoader(state: any, action) {
      state = {
        ...state,
        mainLoader: {
          isVisible: action.payload.isVisible
        },
      }
      return state;
    }
  }
})

export const {toggleMainLoader} = appSlice.actions;

export default appSlice.reducer;

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  MDBBtn,
  MDBDropdown,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBInput,
  MDBRow, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBCheckbox
} from 'mdb-react-ui-kit';
import { ApiClient } from '../../api_client';
import { useSelector } from 'react-redux';
import { getLinkedinAccount, getUserId, UserSocialMedia } from '../../store/user.reducer';
import './message_preview.css';
import VanillaCalendar, { Options } from '@uvarov.frontend/vanilla-calendar';
import '@uvarov.frontend/vanilla-calendar/build/vanilla-calendar.min.css';
import '@uvarov.frontend/vanilla-calendar/build/themes/dark.min.css';
import emojiData from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Loader from '../loader/loader';
import { toast } from 'react-toastify';
import PostHeader from '../post_header/post_header';
import { useTranslation } from 'react-i18next';

import MessageAttachments from '../message_attachments/message_attachments';
import axios from 'axios';
import { getSelectedClient } from '../../store/manager.reducer';

let calendar: any = null;
let textareaCursorPosition: number = 0;

const MessagePreview = (props: any) => {

  const {t} = useTranslation();

  const userId = useSelector(getUserId);
  const linkedinAccount: UserSocialMedia = useSelector(getLinkedinAccount);
  const selectedClient = useSelector(getSelectedClient);

  const [previewMessage, setPreviewMessage]: [any, any] = useState('');
  const [autoComment, setAutoComment]: [any, any] = useState('');
  const [scheduledPostMode, setScheduledPostMode]: [any, any] = useState(false);
  const [scheduledDate, setScheduledDate]: [any, any] = useState(new Date());
  const [isEmojiPickerShown, toggleEmojiPicker]: [any, any] = useState(false);
  const [pendingRequest, setPendingRequest]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false);
  const [attachmentFiles, setAttachmentFiles]: [any, any] = useState([]);
  const [showAutomaticComments, setShowAutomaticComments]: [any, any] = useState(false);

  const attachmentsCompRef: any = useRef();

  const calendarRef = useRef<any>();
  const loaderType: any = useRef<any>('postGeneration');
  const emojiPickerRef = useRef<any>(null);

  // any type because typing in calendar lib is complete BS
  const minDate: any = new Date();
  const maxDate: any = new Date();
  maxDate.setFullYear(minDate.getFullYear() + 1);

  const calendarOptions: Partial<Options> = {
    input: true,
    date: {
      min: minDate.toISOString().slice(0, 10),
      max: maxDate.toISOString().slice(0, 10),
      today: new Date(),
    },
    settings: {
      selection: {
        time: 24,
      },
    },
    actions: {
      changeToInput(e: any, HTMLInputElement: any, dates: any, time: any, hours: any, minutes: any) {
        e.stopPropagation();
        if (dates[0]) {
          setScheduledDate(new Date(`${dates} ${time}`))
          HTMLInputElement.value = formatDateTime(scheduledDate);
        } else {
          HTMLInputElement.value = '';
        }
      },
    },
  };

  const formatDateTime = (date: any) => {
    return `${date.toLocaleDateString()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

  const sanitizeMessage = (message: string) => {
    // Removes trailing line breaks
    return message.replace(/(\r?\n|\r)/g, '')
      .replaceAll('**', '')
      .replace(/\s{2,}/g, ' ')
      .replace(/^\s+|\s+$/g, '');
  }

  useEffect(() => {
    if (calendarRef.current) {
      if (calendar === null) {
        calendar = new VanillaCalendar('#scheduler-date', calendarOptions);
        calendar.init();
      }
    }
  });

  useEffect(() => {
    // Destroy calendar on component destroy
    return () => {
      calendar = null;
    }
  }, [])

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        if (event.target.id !== 'post-textarea' && event.target.id !== 'emoji-picker-toggler') {
          toggleEmojiPicker(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emojiPickerRef]);

  useEffect(() => {
    if (props.messageParameters) {
      try {
        if (props.messageParameters.post) {
          setPreviewMessage(props.messageParameters.post);
        }
      } catch (e) {
        console.error(e);
      }

      setShowAutomaticComments(false);
      setAutoComment('');

      if (props.messageParameters.cta) {
        setShowAutomaticComments(true);
        setAutoComment(sanitizeMessage(props.messageParameters.cta));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.messageParameters, userId]);

  useEffect(() => {
    if (props.pendingRequest !== null) {
      loaderType.current = 'postGeneration';
      setPendingRequest(props.pendingRequest);
    }
  }, [props.pendingRequest]);

  const scheduleMessage = () => {
    loaderType.current = 'postSending';
    setPendingRequest(true);
    const payload = {
      user: userId,
      client: selectedClient,
      message: previewMessage,
      comments: showAutomaticComments ? [autoComment] : null,
      attachments: attachmentFiles.map((file: any) => ({
        fileName: file.name,
        size: file.size,
        type: file.type
      })),
      providers: Object.keys(props.selectedSocialMedia).filter(key => props.selectedSocialMedia[key]),
      publicationTimestamp: Math.round((scheduledPostMode ? scheduledDate.getTime() : new Date().getTime()) / 1000)
    };
    try {
      ApiClient.scheduleMessage(payload)
        .then(async (response: any) => {
          if (response?.data?.uploadUrl) {
            await Promise.allSettled(
              response.data.uploadUrl.map(async (url: { value: string, status: string }, index: number) => {
                if (url.status === 'fulfilled') {
                  return await axios.put(url.value, attachmentFiles[index], {
                    headers: {
                      'Content-Type': attachmentFiles[index].type
                    }
                  });
                }
              })
            );
          }

          setPreviewMessage('');
          attachmentsCompRef?.current?.clearFileAttachment();
          toast.success(scheduledPostMode ? t('postPreview.messageScheduled') : t('postPreview.messageSent'));
        })
        .catch(err => {
          toast.error(t('shared.errors.unexpected'));
          console.error(err);
        })
        .finally(() => {
          setPendingRequest(false);
        })
    } catch (error) {
      toast.error(t('shared.errors.unexpected'));
      console.log(error)
    }
  }

  const insertEmoji = (emojiEvent: any) => {
    setPreviewMessage(previewMessage.slice(0, textareaCursorPosition) + emojiEvent.native + previewMessage.slice(textareaCursorPosition, previewMessage.length))
    textareaCursorPosition += emojiEvent.native.length;
    toggleEmojiPicker(false);
  }

  const updateTextareaCursorPosition = () => {
    textareaCursorPosition = document.getElementsByTagName('textarea')[0].selectionEnd
  }

  const previewBlock = () => {
    let render: any = '';
    if (linkedinAccount && linkedinAccount.isConnected) {
      render = (
        <MDBCard>
          <MDBCardBody className="d-flex flex-column">
            <Loader messageType={loaderType.current} isVisible={pendingRequest}/>

            <MDBCardTitle className="fs-4 mb-4">
              {t('postPreview.title')}
            </MDBCardTitle>
            <div id="post-preview" className="d-flex flex-column flex-grow-1">
              <PostHeader socialMediaAccount={linkedinAccount}/>
              <div id="preview-textarea" className="h-100">
              <textarea
                id="post-textarea"
                className="h-100"
                placeholder={t('postPreview.message')}
                spellCheck={false}
                onChange={(event: any) => setPreviewMessage(event.target.value)}
                onClick={updateTextareaCursorPosition}
                onKeyUp={updateTextareaCursorPosition}
                value={previewMessage}
              />
              </div>
            </div>

            <MessageAttachments
              ref={attachmentsCompRef}
              setAttachmentFiles={setAttachmentFiles}
              messageParameters={props.messageParameters}
            >
              <div id="emoji-picker">
                <MDBBtn id="emoji-picker-toggler" className="fs-6" color="tertiary" outline
                        onClick={() => toggleEmojiPicker(!isEmojiPickerShown)}>
                  <MDBIcon far icon="grin"/>
                </MDBBtn>
                <div ref={emojiPickerRef} className={isEmojiPickerShown ? '' : 'd-none'}>
                  <Picker data={emojiData} onEmojiSelect={insertEmoji} previewPosition="none" navPosition="bottom"/>
                </div>
              </div>
            </MessageAttachments>

            <MDBRow>
              <MDBCol>
                <div className="position-relative">
                  <MDBCheckbox
                    label={t('postPreview.automaticFirstComment')}
                    id="toggle-automatic-comment"
                    name="toggleAutomaticComment"
                    wrapperClass="my-3"
                    checked={showAutomaticComments || ''}
                    onChange={() => setShowAutomaticComments(!showAutomaticComments)}
                  />
                  <textarea
                    id="auto-comment-textarea"
                    className={`${showAutomaticComments ? 'd-block' : 'd-none'} mb-3`}
                    placeholder={t('postPreview.typeFirstComment')}
                    spellCheck={false}
                    onChange={(event: any) => setAutoComment(event.target.value)}
                    value={autoComment}
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className="row-cols-sm-2">
              <MDBCol className="col-sm-12 col-xl-6">
                <MDBInput id="scheduler-date" ref={calendarRef} size="lg"
                          className={`h-100 ${scheduledPostMode ? '' : 'd-none'} mb-3`}
                          placeholder={t('postPreview.pickDate')}
                          value={formatDateTime(scheduledDate)}
                          disabled={previewMessage.trim().length === 0}
                />
              </MDBCol>
              <MDBCol className="col-sm-12 col-xl-6">
                <MDBDropdown className={`btn-group w-100 ${previewMessage.trim().length === 0 ? 'disabled' : ''}`}>
                  <MDBBtn className="post-button w-100 fw-bold px-2"
                          onClick={scheduleMessage}
                          color="primary"
                          size="lg"
                          disabled={previewMessage.trim().length === 0}
                  >
                    {scheduledPostMode ? t('postPreview.schedule') : t('postPreview.send')}
                  </MDBBtn>
                  <MDBDropdownToggle split size="lg" className="px-3"
                                     disabled={previewMessage.trim().length === 0}/>
                  <MDBDropdownMenu size="lg" className={`w-100 post-schedule-type`}>
                    <MDBDropdownItem
                      onClick={() => {
                        setScheduledPostMode(false);
                      }}
                    >
                      {t('postPreview.send')}
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      onClick={() => {
                        setScheduledPostMode(true);
                      }}
                    >
                      {t('postPreview.schedule')}
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      )
    }
    return render;
  }

  return (
    <>
      {previewBlock()}
    </>
  )

}

export default MessagePreview;
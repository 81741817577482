export const user_pool_conf = {
  'aws_project_region': process.env.REACT_APP_AWS_REGION,
  'aws_cognito_region': process.env.REACT_APP_AWS_REGION,
  'aws_user_pools_id': process.env.REACT_APP_USER_POOL_ID,
  'aws_user_pools_web_client_id': process.env.REACT_APP_USER_POOL_CLIENT_ID,
};

export const POST_INPUTS = {
  mainFields: {
    socialAwareness: {
      placeholder: 'Social awareness',
      campaignName: {
        type: 'text',
        placeholder: 'Campaign name',
        length: 512
      },
      companyName: {
        type: 'text',
        placeholder: 'Company name',
        length: 512,
        sensitive: true
      },
      targetAudience: {
        type: 'text',
        placeholder: 'Target audience',
        length: 512
      }
    },
    advices: {
      placeholder: 'Advices',
      advicesAbout: {
        type: 'text',
        placeholder: 'A subject on which you wish to give advice',
        length: 512
      },
      targetAudience: {
        type: 'text',
        placeholder: 'Target audience',
        length: 512
      }
    },
    knowledgeShare: {
      placeholder: 'Knowledge share',
      shareAbout: {
        type: 'text',
        placeholder: 'A subject you wish to share',
        length: 512
      },
      targetAudience: {
        type: 'text',
        placeholder: 'Target audience',
        length: 512
      }
    },
    hiring: {
      placeholder: 'Hiring',
      companyName: {
        type: 'text',
        placeholder: 'Company name',
        length: 512,
        sensitive: true
      },
      jobTitle: {
        type: 'text',
        placeholder: 'Job title',
        length: 512
      },
      startDate: {
        type: 'date',
        placeholder: 'Start date',
      },
      location: {
        type: 'text',
        placeholder: 'Location',
        length: 512
      },
      skills: {
        placeholder: 'Enter a skill and press Enter',
        type: 'tag',
      },
      requiredExp: {
        type: 'number',
        placeholder: 'Required experience (years)',
        length: 2
      }
    },
    iAmlookingForJob: {
      placeholder: 'Looking for a job',
      wantedOpportunity: {
        type: 'text',
        placeholder: 'Wanted opportunity',
        length: 512
      },
      date: {
        type: 'date',
        placeholder: 'Available from'
      },
      location: {
        type: 'text',
        placeholder: 'Location',
        length: 512
      }
    },
    eventsAndWebinars: {
      placeholder: 'Events and Webinars',
      eventName: {
        type: 'text',
        placeholder: 'Event Name',
        length: 512
      },
      location: {
        type: 'text',
        placeholder: 'Location',
        length: 512
      },
      companyName: {
        type: 'text',
        placeholder: 'Company Name',
        length: 512,
        sensitive: true
      },
      productName: {
        type: 'text',
        placeholder: 'Product Name',
        length: 512,
        sensitive: true
      },
      startDate: {
        type: 'date',
        placeholder: 'Start date',
      },
      endDate: {
        type: 'date',
        placeholder: 'End date',
      },
      collaborationHighlights: {
        type: 'tag',
        placeholder: 'Enter a highlight and press Enter',
      }
    },
    greetingWish: {
      placeholder: 'Greeting wish',
      occasionName: {
        type: 'text',
        placeholder: 'Occasion name',
        length: 512
      },
      date: {
        type: 'date',
        placeholder: 'Date',
      },
      targetAudience: {
        type: 'text',
        placeholder: 'Target audience',
        length: 512
      }
    },
    productLaunch: {
      placeholder: 'Product launch',
      productName: {
        type: 'text',
        placeholder: 'Product name',
        length: 512,
        sensitive: false
      },
      companyName: {
        type: 'text',
        placeholder: 'Company name',
        length: 512,
        sensitive: true
      },
      launchDate: {
        type: 'date',
        placeholder: 'Launch date'
      },
      productCategory: {
        type: 'select',
        placeholder: 'Choose a product category',
        label: 'selectProductCategory',
        list: [
          {
            value: 'hightech',
            name: 'High-tech'
          },
          {
            value: 'agricultural',
            name: 'Agriculture'
          },
          {
            value: 'fashion',
            name: 'Fashion'
          },
          {
            value: 'automobile',
            name: 'Automobile'
          },
          {
            value: 'service',
            name: 'Service'
          }
        ]
      },
      productFeatures: {
        placeholder: 'Enter a feature and press Enter',
        type: 'tag',
      },
    },
    caseStudies: {
      placeholder: 'Case Studies',
      projectName: {
        type: 'text',
        placeholder: 'Project Name',
        length: 512,
        sensitive: true
      },
      clientName: {
        type: 'text',
        placeholder: 'Client Name',
        length: 512,
        sensitive: true
      },
      outcome: {
        type: 'text',
        placeholder: 'Outcome',
        length: 256
      }
    },
    collaborationsAndPartnerships: {
      placeholder: 'Collaborations and Partnerships',
      partnerName: {
        type: 'text',
        placeholder: 'Partner Name',
        length: 512,
        sensitive: true
      },
      collaborationDetails: {
        type: 'text',
        placeholder: 'Collaboration Details',
        length: 256
      }
    },
    milestonesAndAchievements: {
      placeholder: 'Milestones and Achievements',
      milestoneName: {
        type: 'text',
        placeholder: 'Milestone Name',
        length: 512
      },
      achievementDetails: {
        type: 'text',
        placeholder: 'Achievement Details',
        length: 256
      },
      date: {
        type: 'date',
        placeholder: 'Date Achieved'
      }
    },
    employeeSpotlights: {
      placeholder: 'Employee Spotlights',
      employeeName: {
        type: 'text',
        placeholder: 'Employee Name',
        length: 512,
        sensitive: true
      },
      role: {
        type: 'text',
        placeholder: 'Role',
        length: 512
      },
      contribution: {
        type: 'text',
        placeholder: 'Contribution',
        length: 256
      }
    },
    thankYouMessage: {
      placeholder: 'Thank you message (BETA)',
      partnerName: {
        type: 'tag',
        placeholder: 'Type a name then Enter',
        sensitive: true
      },
      relationship: {
        type: 'select',
        placeholder: 'Who was your partner ?',
        list: [
          {
            value: 'customer',
            name: 'Your business client(s)'
          },
          {
            value: 'colleague',
            name: 'Your colleague(s)'
          },
          {
            value: 'relative',
            name: 'A relative(s)'
          },
          {
            value: 'friend',
            name: 'Your friend(s)'
          },
          {
            value: 'company',
            name: 'A company'
          },
          {
            value: 'publicFigure',
            name: 'A public figure'
          }
        ]
      }
    },
    communityInvolvement: {
      placeholder: 'Community Involvement',
      activityName: {
        type: 'text',
        placeholder: 'Activity Name',
        length: 512
      },
      location: {
        type: 'text',
        placeholder: 'Location',
        length: 512
      },
      date: {
        type: 'date',
        placeholder: 'Activity Date'
      },
      description: {
        type: 'text',
        placeholder: 'Description',
        length: 256
      }
    }
  },
  advancedFields: {
    role: {
      type: 'select',
      placeholder: 'Choose a role',
      list: [
        {
          value: 'humanResource',
          name: 'Human resource'
        },
        {
          value: 'headhunter',
          name: 'Headhunter'
        },
        {
          value: 'entrepreneur',
          name: 'Entrepreneur'
        },
        {
          value: 'coach',
          name: 'Coach'
        },
        {
          value: 'copywriter',
          name: 'Copywriter'
        },
        {
          value: 'lawyer',
          name: 'Lawyer'
        },
        {
          value: 'marketer',
          name: 'Marketer'
        },
        {
          value: 'realtor',
          name: 'Realtor'
        },
        {
          value: 'teacher',
          name: 'Teacher'
        },
        {
          value: 'engineer',
          name: 'Engineer'
        }
      ]
    },
    tone: {
      type: 'select',
      placeholder: 'Select a tone',
      label:'selectTone',
      list: [
        {
          'value': 'informative',
          'name': 'Informative'
        },
        {
          'value': 'exciting',
          'name': 'Exciting'
        },
        {
          'value': 'persuasive',
          'name': 'Persuasive'
        },
        {
          'value': 'professional',
          'name': 'Professional'
        },
        {
          'value': 'friendly',
          'name': 'Friendly'
        },
        {
          'value': 'inspirational',
          'name': 'Inspirational'
        },
        {
          'value': 'humorous',
          'name': 'Humorous'
        },
        {
          'value': 'empowering',
          'name': 'Empowering'
        },
        {
          'value': 'urgent',
          'name': 'Urgent'
        },
        {
          'value': 'personal',
          'name': 'Personal'
        },
        {
          'value': 'conversational',
          'name': 'Conversational'
        },
        {
          'value': 'thoughtProvoking',
          'name': 'Thought-provoking'
        },
        {
          'value': 'authentic',
          'name': 'Authentic'
        },
        {
          'value': 'sophisticated',
          'name': 'Sophisticated'
        },
        {
          'value': 'energetic',
          'name': 'Energetic'
        }
      ]
    },
    format: {
      type: 'select',
      placeholder: 'Select a format',
      label:'selectFormat',
      list: [
        {
          value: 'oneSentence',
          name: '1 sentence'
        },
        {
          value: 'oneParagraph',
          name: '1 paragraph'
        },
        {
          value: 'severalParagraphs',
          name: 'Formatted paragraphs'
        },
        {
          value: 'textWithBulletPoints',
          name: 'Text with bullet points'
        }
      ]
    },
    pronoun: {
      type: 'select',
      placeholder: 'Select a pronoun',
      list: [
        {
          value: 'i',
          name: 'I'
        },
        {
          value: 'we',
          name: 'We'
        },
        {
          value: 'whatever',
          name: 'Whatever'
        }
      ]
    },
    keywords: {
      placeholder: 'Enter a keyword and press Enter',
      type: 'tag',
    },
    cta: {
      type: 'text',
      placeholder: 'CTA link',
      length: 512
    },
    emojis: {
      type: 'range',
      placeholder: 'Emojis:',
      lowLabel: 'Low',
      midLabel: 'Medium',
      highLabel: 'High',
      min: 0,
      max: 16,
      step: 8,
      defaultValue: 8
    },
    creativity: {
      type: 'range',
      placeholder: 'Creativity:',
      lowLabel: 'Low',
      midLabel: 'Medium',
      highLabel: 'High',
      min: 0,
      max: 1,
      step: 0.01,
      defaultValue: 0.7
    },
    parameters: {
      type: 'checkboxList',
      id: 'parameters',
      list: [
        {
          name: 'hashtags',
          label: 'Use hashtags',
        },
        {
          name: 'hookSentence',
          label: 'Eye catching 1st sentence',
        },
      ]
    },
  }
}

export const SOCIAL_ACCOUNTS: any = {
  linkedin: {
    provider: 'linkedin',
    icon: 'linkedin',
    iconColor: '#0077b5'
  },
  facebook: {
    provider: 'facebook',
    icon: 'facebook',
    iconColor: '#3b5998'
  },
  instagram: {
    provider: 'instagram',
    icon: 'instagram',
    iconColor: '#000000'
  }
}

export const formatName = (firstName: string | undefined, lastName: string | undefined) => {
  let fullName = '';
  if (firstName) {
    fullName += firstName;
  }
  if (lastName) {
    fullName += fullName.length > 0 ? ` ${lastName}` : lastName;
  }
  return fullName.length > 0 ? fullName : 'You';
}

export const IMAGE_FORMATS = ['jpg', 'jpeg'];
export const VIDEO_FORMATS = ['mp4'];
export const DOC_FORMATS = ['pdf', 'doc', 'docx', 'ppt', 'pptx'];

export const IS_IMAGE = (fileName: string | undefined) => IMAGE_FORMATS.some(extension => fileName?.includes(extension));
export const IS_VIDEO = (fileName: string | undefined) => VIDEO_FORMATS.some(extension => fileName?.includes(extension));
export const IS_DOC = (fileName: string | undefined) => DOC_FORMATS.some(extension => fileName?.includes(extension));

export const FILES_UPLOAD_REQUIREMENTS = {
  image : {
    maxSize: 10, // MB
    maxDimension: 36152320,
    format: IMAGE_FORMATS
  },
  video: {
    minSize: 0.75, // MB
    maxSize: 100, // MB
    format: VIDEO_FORMATS
  },
  doc: {
    maxSize: 10, // MB
    format: DOC_FORMATS
  }
}
import React, { useEffect, useRef, useState } from 'react';
import './loader.css';

const Loader = ({messageType, isVisible}: {messageType: string, isVisible: boolean}) => {

  const messages = {
    postGeneration: [
      "Preparing a dash of inspiration and creativity...",
      "Hold tight, we're stirring up some brilliant ideas...",
      "Mixing the perfect blend of words and imagination...",
      "Unleashing the power of creativity...",
      "Loading up the content machine with a sprinkle of magic!",
      "Gathering thoughts from the universe...",
      "Igniting the creative spark...",
      "Pouring in creativity...",
      "Harnessing the force of imagination...",
      "Building bridges between words and ideas...",
      "Embracing the art of imagination and creation...",
      "Unleashing boundless ideas from the depths of creativity...",
      "Crafting content that sparks inspiration and captivates minds...",
      "Weaving words into a tapestry of imagination and wonder...",
      "Unlocking the door to a world where ideas know no limits...",
      "Diving into the realm of possibilities and bringing them to life...",
      "Igniting the fire of creativity that fuels our content journey...",
      "Exploring uncharted territories of thought and expression...",
      "Navigating the vast universe of ideas...",
      "Merging creativity and intellect to shape captivating narratives...",
      "Transcending boundaries and redefining the realms of imagination...",
      "Harnessing the power of words to paint vivid stories in the mind...",
      "Elevating content creation to new heights of brilliance...",
      "Delving into the depths of inspiration to create something extraordinary...",
      "Finding beauty in the symphony of words and ideas...",
      "Crafting a symphony of creativity that resonates with the world...",
      "Transforming thoughts and dreams into tangible and compelling content...",
      "Embarking on a quest to redefine what's possible through words...",
      "Infusing the world with a dose of inspiration and thought-provoking content...",
      "Weaving threads of creativity into the fabric of storytelling..."
    ],
    postSending: [
      "Holding on tight while we deliver your message 🚀",
      "Preparing to launch your message to the server 📩🚀",
      "Message transmission in progress... 📡",
      "Loading your thoughts into the digital universe 🌌💭",
      "Sit tight! Your message is on its way 🏇📝",
      "Gearing up to send your message through cyberspace 🌐🚀",
      "Spreading your message across the digital realm 💌💻",
      "In transit: Your message is en route to the server 🚚➡️🌐",
      "Patience, we're making sure your message arrives safely 📤🔒",
      "Initiating message transfer, stand by for delivery 📡🚀",
      "Loading your thoughts and feelings into the electronic ether 🌌💬",
      "Your message is in good hands, just a moment more ⏳✉️",
      "Connecting the dots and sending your message across the web 🕸️🌐",
      "Beep boop beep... Your message is being transmitted 🤖💬",
      "The journey of your message begins now 🌠🚀",
      "Sending your words on a digital adventure 🚀🌟",
      "Transmitting your message with care and precision 📡✨",
      "Your message is like a comet, speeding towards the server 🌠📡",
      "Stay tuned, your message is making its way through the interwebs 📶🌐",
      "Your words are about to embark on an electronic odyssey 📝🚀"
    ],
    default: [
      "Gathering the internet's funniest cat videos... this might take a while. 😺📹",
      "Locating the lost city of Atlantis... just kidding, we're fetching your data! 🌊🚢",
      "Assembling a team of highly trained squirrels to fetch your info. Please wait. 🐿️🌰",
      "Preparing a cup of virtual coffee while we work our magic. ☕✨",
      "Consulting with our psychic server to predict your data's arrival time. 🔮⏳",
      "Sending out carrier pigeons to retrieve your information... hope they don't get distracted! 🐦💌",
      "Waiting for the data to jump out of the screen and into your device. 🖥️🔄",
      "Convincing the hamster on the wheel to run faster... almost there! 🐹🏃",
      "Checking under the virtual couch cushions for your data. It's gotta be here somewhere! 🛋️🔍",
      "Holding a dance-off with our servers to see who can fetch your data faster. Disco inferno! 💃🕺",
      "Uploading happy thoughts to the cloud to make your wait more enjoyable. 😄☁️",
      "Counting binary stars to pass the time... 01001000 01000101 01001100 01010000! 💫🌟",
      "Teaching our AI chatbot to tap dance while your data loads. Shuffle, shuffle, step! 🕺🩰",
      "Transmitting your request through a wormhole in cyberspace. 🌌🌀",
      "Harnessing the power of 1.21 gigawatts to speed up your data retrieval. ⚡🔌",
      "Casting a spell to summon your information from the digital realm. 🪄✨",
      "Conducting a virtual orchestra to create a symphony of data retrieval. 🎶🖥️",
      "Launching a rocket to fetch your data from the digital stratosphere. 🚀🌌",
      "Baking a digital cake while you wait. 🍰👩‍🍳",
      "Hosting a virtual tea party for your data to arrive fashionably late. ☕🍪"
    ]
  };

  const pickRandomQuote = () => {
    const quotesArray: any = messages[messageType as keyof typeof messages];
    const randomIndex = Math.floor(Math.random() * quotesArray.length);
    return quotesArray[randomIndex];
  }

  let quoteIntervalRef: any = useRef();
  const [quote, setQuote]: [any, any] = useState(pickRandomQuote());

  if (!quoteIntervalRef.current) {
    quoteIntervalRef.current = setInterval(() => {
      setQuote(pickRandomQuote());
    }, 5000);
  }

  useEffect(() => {
    return () => {
      clearInterval(quoteIntervalRef.current);
    }
  }, [])

  return (
    <div className={`loader ${isVisible ? 'isVisible' : 'isHidden'}`}>
      <span className="flex-fill fs-5 fst-italic">
        <div className="loading-dot"></div>
        <span className="loading-quote">
          {quote}
        </span>
      </span>
    </div>
  )

}

export default Loader;
import React, { useEffect, useState } from 'react';
import './home.css';
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import PaymentPage from '../payment_page/payment_page';
import Footer from '../shared/parts/footer';

export default function Home() {

  const {t} = useTranslation();

  const [headerBgClass, setHeaderBgClass] = useState('');
  const headerBgTransition = 50;

  useEffect(() => {
    const shrinkedHeaderClass = 'shrinked'
    const onScroll = (e: any) => {
      if (e.target.scrollTop > headerBgTransition && headerBgClass === '') {
        setHeaderBgClass(shrinkedHeaderClass);
      }
      if (e.target.scrollTop <= headerBgTransition && headerBgClass === shrinkedHeaderClass) {
        setHeaderBgClass('');
      }
    }

    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    }
  }, [headerBgClass])

  return (
    <div id="homepage">
      <nav className={headerBgClass}>
        <MDBContainer>
          <MDBRow>
            <MDBCol className="d-flex">
              <Link to="/" aria-label="Feedler.io">
                <div id="logo" className="position-relative text-center">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/feedler_white_logo.png`} alt=""/>
                </div>
              </Link>
            </MDBCol>
            <MDBCol className="d-flex align-items-center">
              <div className="text-end w-100">
                <Link to="/sign-in">
                  <MDBBtn className="cta-btn me-1 fw-bold py-2" size="lg" rounded>
                    {t('shared.logIn')}
                  </MDBBtn>
                </Link>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </nav>
      <div id="hero" className="d-flex h-100 flex-column">
        <MDBContainer className="catchLine d-flex m-auto align-items-center">
          <MDBRow className="w-100">
            <MDBCol className="col-6 text-white ">
              <h1>
                <Trans i18nKey="home.punchline">
                  <span className="text-cta fw-bold"></span>
                </Trans>
              </h1>
              <p>
                Optimisez votre temps et concentrez-vous sur votre coeur de métier, l'IA vous aide à rédiger vos
                publications.
              </p>
              <Link to="/app/payment">
                <MDBBtn className="cta-btn mt-1 fw-bold bg-white" size="lg">
                  {t('home.getStarted')}
                </MDBBtn>
              </Link>
            </MDBCol>
            <MDBCol className="col-6 text-center d-flex ">
              <div className="rocket">
                <img src={`${process.env.PUBLIC_URL}/assets/images/rocket.png`} alt=""/>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="parallax-shape top-shape"
             style={{backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/stars.png')`}}
        />
        <div className="parallax-shape bottom-shape"
             style={{backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/clouds.png')`}}
        >
        </div>

        <MDBContainer className="position-relative">
          <div className="floating-logos">
            <div className="social-logo-wrapper">
              <img className="social-media-logo" src={`${process.env.PUBLIC_URL}/assets/images/linkedin.png`}
                   alt="Linkedin logo"/>
              <img className="cloud right" src={`${process.env.PUBLIC_URL}/assets/images/cloud.png`}
                   alt="Cloud"/>
            </div>
            <div className="social-logo-wrapper">
              <img className="social-media-logo" src={`${process.env.PUBLIC_URL}/assets/images/x.png`} alt="X logo"/>
              <img className="cloud left" src={`${process.env.PUBLIC_URL}/assets/images/cloud.png`}
                   alt="Cloud"/>
            </div>
            <div className="social-logo-wrapper">
              <img className="social-media-logo" src={`${process.env.PUBLIC_URL}/assets/images/instagram.png`}
                   alt="Instagram logo"/>
              <img className="cloud left" src={`${process.env.PUBLIC_URL}/assets/images/cloud.png`}
                   alt="Cloud"/>
            </div>
            <div className="social-logo-wrapper">
              <img className="social-media-logo" src={`${process.env.PUBLIC_URL}/assets/images/facebook.png`}
                   alt="Facebook logo"/>
              <img className="cloud right" src={`${process.env.PUBLIC_URL}/assets/images/cloud.png`}
                   alt="Cloud"/>
            </div>
          </div>
        </MDBContainer>
        {/*<img className="parallax-shape bottom-shape" src={`${process.env.PUBLIC_URL}/assets/images/clouds.png`} alt=""/>*/}
      </div>

      <section className="pt-5 overflow-hidden">
        <MDBContainer>
          <MDBRow className="py-5 mb-5 feature">
            <MDBCol className="col-7">
              <Link to="/app/payment">
                <img className="cloud left" src={`${process.env.PUBLIC_URL}/assets/images/home/post-generator.png`}
                     alt="Cloud"/>
              </Link>
            </MDBCol>
            <MDBCol className="col-5">
              <div>
                <h3>
                  {t('home.feature1.title')}
                </h3>
                <p className="description">
                  {t('home.feature1.description')}
                </p>
                <p className="footer">
                  {t('home.feature1.footer')}
                </p>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="py-5 mb-5 feature">
            <MDBCol className="col-5">
              <div>
                <h3>
                  {t('home.feature2.title')}
                </h3>
                <p className="description">
                  {t('home.feature2.description')}
                </p>
                <p className="footer">
                  {t('home.feature2.footer')}
                </p>
              </div>
            </MDBCol>
            <MDBCol className="col-7 text-end">
              <Link to="/app/payment">
                <img className="cloud left" src={`${process.env.PUBLIC_URL}/assets/images/home/social-connect.png`}
                     alt="Cloud"/>
              </Link>
            </MDBCol>
          </MDBRow>
          <MDBRow className="pt-5 pb-1 feature">
            <MDBCol className="col-7">
              <Link to="/app/payment">
                <img className="cloud left" src={`${process.env.PUBLIC_URL}/assets/images/home/post.png`}
                     alt="Cloud"/>
              </Link>
            </MDBCol>
            <MDBCol className="col-5">
              <div>
                <h3>
                  {t('home.feature3.title')}
                </h3>
                <p className="description">
                  {t('home.feature3.description')}
                </p>
                <p className="footer">
                  {t('home.feature3.footer')}
                </p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section id="pricing-section">
        <div className="parallax-shape bottom-shape"
             style={{backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/clouds.png')`}}
        />
        <PaymentPage darkMode={true}/>
      </section>
      <Footer darkMode={true}/>
    </div>
  );
}

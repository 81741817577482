import React from 'react';
import { MDBBtn, MDBIcon, MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import './sidebar.css';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { getUser, logoutUser } from '../store/user.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const {t} = useTranslation();

  const disconnect = () => {
    Auth.signOut()
      .then(() => {
        dispatch(logoutUser());
        navigate(0)
      });
  };

  return (
    <div id="sidebar" className="shadow-custom">
      <Link to="/">
        <div id="logo" className="shadow-custom position-relative text-center bg-primary p-3">
          <img src={`${process.env.PUBLIC_URL}/assets/images/feedler_white_logo.png`} alt=""/>
        </div>
      </Link>

      <div className="my-4 px-3 new-post-btn">
        <Link to="/app/new-post">
          <MDBBtn className="mx-auto w-100 fw-bold" size="lg" color="primary" rounded>
            {t('sidebar.newPost')}
            <MDBIcon className="ms-2" fas icon="paper-plane"/>
          </MDBBtn>
        </Link>
      </div>

      <MDBListGroup className="mt-3">
        {
          user.role === 'manager' &&
          <Link to="/app/dashboard">
            <MDBListGroupItem active={window.location.href.includes('dashboard')}>
              {t('sidebar.dashboard')}
            </MDBListGroupItem>
          </Link>
        }
        <Link to="/app/timeline">
          <MDBListGroupItem active={window.location.href.includes('timeline')}>
            {t('sidebar.timeline')}
          </MDBListGroupItem>
        </Link>
        <Link to="/app/calendar">
          <MDBListGroupItem active={window.location.href.includes('calendar')}>
            {t('sidebar.calendar')}
          </MDBListGroupItem>
        </Link>
        <Link to="/app/account-settings">
          <MDBListGroupItem active={window.location.href.includes('account-settings')}>
            {t('sidebar.settings')}
          </MDBListGroupItem>
        </Link>
        <MDBListGroupItem onClick={disconnect}>
          {t('shared.disconnect')}
        </MDBListGroupItem>
      </MDBListGroup>
    </div>
  );
};

export default Sidebar;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import * as enTranslation from './en.json';
import * as frTranslation from './fr.json';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: navigator.language,
    resources: {
      en: {
        translation: enTranslation
      },
      fr: {
        translation: frTranslation
      }
    }
  });

export default i18n;

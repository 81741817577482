import React, { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import './timeline.css';
import { MDBRow } from 'mdb-react-ui-kit';
import { ApiClient } from '../../api_client';
import { useSelector } from 'react-redux';
import { getUserId, getUserRole } from '../../store/user.reducer';
import Post, { ScheduledMessage } from '../post/post';
import Loader from '../loader/loader';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { getSelectedClient } from '../../store/manager.reducer';

const Timeline: React.FC = () => {

  const { i18n } = useTranslation();
  const userId = useSelector(getUserId);

  const userRole = useSelector(getUserRole);
  const selectedClient = useSelector(getSelectedClient);
  const [managedUser, setManagedUser]: any = useState();

  const [posts, setPosts]: [ScheduledMessage[], any] = useState([]);
  const [pendingRequest, setPendingRequest]: [boolean, any] = useState(true);

  const refreshPosts = useCallback(() => {
    setPendingRequest(true);
    if (managedUser) {
      ApiClient.getScheduledMessages(managedUser)
        .then((result: any) => {
          setPosts(result.data.body);
        })
        .finally(() => setPendingRequest(false));
    }
  }, [managedUser]);

  const renderTimeline = () => {
    let postsRender: any[] = [];
    const timelineSection: any[] = [];
    const postsMap = formatPostsForTimeline(posts);
    Object.keys(postsMap).forEach(date => {
      postsMap[date].forEach((post: ScheduledMessage, index: number) => {
        postsRender.push((<Post postData={post} key={index} deletionCallback={refreshPosts}/>));
      });

      const dateObject = new Date(date);
      const formattedDate = `${dateObject.toLocaleString(i18n.language, {month: 'long'})} ${dateObject.getFullYear()}`

      timelineSection.push(
        <div className="timeline-group" key={`masonry-section-${date}`}>
          <span className="timeline-date">{formattedDate}</span>

          <ResponsiveMasonry
            columnsCountBreakPoints={{600: 1, 1000: 2, 1400: 3, 1800: 4, 2200: 5, 2600: 6, 3000: 7}}
          >
            <Masonry gutter="16px" key={date}>
              {postsRender}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      )

      postsRender = [];

    });
    return timelineSection;
  };

  const formatPostsForTimeline = (posts: ScheduledMessage[]) => {
    const timelinePosts: any = {};
    const sortedPosts = posts.sort((postA, postB) => postB.publicationTimestamp - postA.publicationTimestamp)
    sortedPosts.forEach(post => {
      const date = new Date(post.publicationTimestamp * 1000);
      const dateKey = date.toISOString().slice(0, 7);
      if (!timelinePosts.hasOwnProperty(dateKey)) {
        timelinePosts[dateKey] = [];
      }
      timelinePosts[dateKey].push(post);
    })
    return timelinePosts;
  }

  useEffect(() => {
    if (userRole === 'manager' && selectedClient) {
      setManagedUser(selectedClient);
    } else if (userId) {
      setManagedUser(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, userId]);

  useEffect(() => {
    if (managedUser) {
      refreshPosts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedUser]);

  return (
    <MDBRow className="timeline-wrapper px-3 py-4 m-0">
      {<Loader messageType="default" isVisible={pendingRequest}/>}
      {!pendingRequest && posts.length === 0 &&
        <div>
          <Trans i18nKey="timeline.noMessagePlaceholder">
            No posts scheduled yet.
            <Link to="/app/new-post">
              Create a new post
            </Link>
          </Trans>
        </div>
      }
      {!pendingRequest && posts.length > 0 &&
        <>
          <div className="timeline-ribbon"></div>
          <div className="flex-fill w-auto">
            {renderTimeline()}
          </div>
        </>
      }
    </MDBRow>
  );
};

export default Timeline;


import {createSlice} from '@reduxjs/toolkit';
import { UserState } from './user.reducer';

const initialState = {
  selectedClientId: '',
  clients: []
}

export interface ManagerState {
  selectedClientId: string,
  clients: UserState[]
}

const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    selectClient(state: ManagerState, action: {payload : {clientId: string}}) {
      state.selectedClientId = action.payload.clientId;
    },
    setClients(state: ManagerState, action: {payload : {clients: any}}) {
      return {
        ...state,
        clients: action.payload.clients
      }
    }
  }
})

export const { selectClient, setClients } = managerSlice.actions;

export const getSelectedClient = ((state: any) => state.manager.selectedClientId);
export const getClients = ((state: any) => state.manager.clients || []);

export default managerSlice.reducer;

import React, { useEffect, useState } from 'react';
import './client_selector.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../store/user.reducer';
import { getClients, selectClient as selectClientReducer } from '../../store/manager.reducer';

const ClientSelector = () => {

  const user = useSelector(getUser);
  const clients = useSelector(getClients);

  const [selectedClient, setSelectedClient]: [string, any] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectClientReducer({
      clientId: selectedClient
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    setSelectedClient(user.userId);
    dispatch(selectClientReducer({
      clientId: user.userId
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderClients = () => {
    return clients.map((client: any) => {
      return (
        <option key={client.user} value={client.user}>{client.firstName} {client.lastName}</option>
      )
    })
  }

  const handleSelectClient = (clientId: string) => {
    setSelectedClient(clientId);
  }

  return (
    <>
      {user.role === 'manager' &&
        <div>
          <span className="text-white me-3">Account :</span>
          <select className="client-selector bg-white"
                  onChange={(e) => handleSelectClient(e.target.value)}>
            <option value={user.userId}>{user.email}</option>
            {clients && renderClients()}
          </select>
        </div>
      }
    </>
  )

}

export default ClientSelector;
import React, { useEffect, useState } from 'react';
import Home from './home/home';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignUp from './sign_up/sign_up';
import SignIn from './sign_in/sign_in';
import Main from './main/main';
import { useSelector } from 'react-redux';
import Auth from '@aws-amplify/auth';
import NewPost from './new_post/new_post';
import './app.css';
import { ApiClient } from "./shared/api_client";
import Timeline from "./shared/parts/timeline/timeline";
import ResetPassword from "./reset_password/reset_password";
import AccountSettings from "./account_settings/account_settings";
import PaymentPage from "./payment_page/payment_page";
import { ToastContainer } from "react-toastify";
import Loader from './shared/parts/loader/loader';

import './i18n';
import Calendar from './shared/parts/calendar/calendar';
import Dashboard from './dashboard/dashboard';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [showLoader, setShowLoader]: [any, any] = useState(true);

  const loaderVisibility = useSelector((state: any) => state.app.mainLoader.isVisible);

  useEffect(() => {
    setShowLoader(loaderVisibility)
  }, [loaderVisibility])

  useEffect(() => {
    ApiClient.setupClient();

    // TODO Better way to handle user session (same check is done in main.tsx)
    Auth.currentAuthenticatedUser()
      .then(user => {
        setIsLoggedIn(true);
      })
      .catch(err => {
        setIsLoggedIn(false);
      })
  }, []);

  // TODO better loading screen here (overlay)
  if (isLoggedIn === undefined) return (<div>Loading</div>);

  return (
    <div className="app light-theme">
      <div id="small-screen-disclaimer" className="p-4">
        <h1 className="text text-white">
          Feedler is optimized for desktop use.
          <br/>
          <br/>
          <span className="fs-1 mb-2 d-inline-block">🖥️🤷📵</span>️
          <br/>
          <br/>
          For the best experience, please switch to a larger screen.
        </h1>
      </div>
      <ToastContainer/>
      <Loader messageType="default" isVisible={showLoader}/>
      <Routes>
        <Route path="" element={<Home/>}/>
        <Route path="/sign-up" element={isLoggedIn ? <Navigate to="/app/timeline"/> : <SignUp/>}/>
        <Route path="/sign-in" element={isLoggedIn ? <Navigate to="/app/timeline"/> : <SignIn/>}/>
        <Route path="/reset-password" element={<ResetPassword/>}/>
        { /* TODO Add redirection logic */}
        <Route path="/app" element={isLoggedIn ? <Main/> : <Navigate to="/sign-in"/>}>
          <Route index element={<Navigate to="/app/timeline"/>}/>
          <Route path="new-post" element={<NewPost/>}/>
          <Route path="dashboard" element={<Dashboard/>}/>
          <Route path="timeline" element={<Timeline/>}/>
          <Route path="calendar" element={<Calendar/>}/>
          <Route path="account-settings" element={<AccountSettings/>}/>
          <Route path="payment" element={<PaymentPage/>}/>
          { /* TODO implement 404 page here */}
          <Route path="*" element={<Timeline/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;

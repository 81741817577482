import React, { useEffect, useState } from 'react';
import './sign_in.css';

import { Amplify, Auth } from 'aws-amplify';
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import { user_pool_conf } from '../shared/constants';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleMainLoader } from '../shared/store/app.reducer';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

Amplify.configure(user_pool_conf);

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pendingRequest, setPendingRequest] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    getAccountConfirmationCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(toggleMainLoader({
        isVisible: false
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAccountConfirmationCode = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const code: any = queryParams.get('code');
    const email: any = queryParams.get('email');

    if (code) {
      dispatch(toggleMainLoader({
        isVisible: true
      }))
      Auth.confirmSignUp(email, code)
        .then(() => {
          toast.success(t('signIn.accountVerified'))
        })
        .catch(() => {
          toast.error(t('signIn.accountVerificationFailed'))
        })
        .finally(() => {
          dispatch(toggleMainLoader({
            isVisible: false
          }))
        })
    }
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setPendingRequest(true);
    Auth.signIn({
      username: email,
      password: password,
    }).then(() => {
      // Refresh route
      navigate(0)
    }).catch(error => {
      toast.error(t('signIn.error'));
    }).finally(() => {
      setPendingRequest(false);
    })
  };

  return (
    <div className="sign-in-page">
      <div className="d-flex h-100">
        <MDBRow className="align-self-center w-100 h-100 m-0">
          <MDBCol md="8" className="left d-flex text-center p-0">
            <div className="side-view d-flex w-100 align-items-center">
              <Link to="/" className="m-auto" >
                <img src={`${process.env.PUBLIC_URL}/assets/images/feedler_white_logo.png`} alt=""/>
              </Link>
            </div>
          </MDBCol>
          <MDBCol md="4" className="right bg-white">
            <form className="sign-in-form" onSubmit={handleSubmit}>
              <h2>
                {t('signIn.title')}
              </h2>
              <MDBInput className="mb-3" label={t('shared.Email')} value={email} onChange={(event) => setEmail(event.target.value)}
                        required/>
              <MDBInput className="mb-3" label={t('shared.Password')} type="password" value={password}
                        onChange={(event) => setPassword(event.target.value)} required/>
              <MDBBtn className="btn-with-loader w-100" size="lg" color="primary" type="submit" rounded
                      disabled={pendingRequest}>
                {pendingRequest ? t('signIn.buttonPendingLabel') : t('signIn.buttonLabel')}
                {pendingRequest &&
                  <div className="loader-icon fs-5">
                    <MDBIcon fas icon="circle-notch"/>
                  </div>
                }
              </MDBBtn>
              <Link to="/reset-password" className="mt-3 w-100 d-inline-block text-center fs-7">
                {t('shared.forgotPassword')}
              </Link>
              <Link to="/sign-up" className=" w-100 d-inline-block text-center fs-7">
                {t('shared.createAccount')}
              </Link>
            </form>
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  );
}

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { POST_INPUTS } from '../../constants';
import {
  MDBBtn,
  MDBCheckbox,
  MDBCol,
  MDBCollapse,
  MDBIcon,
  MDBInput,
  MDBRange,
  MDBRow,
  MDBTooltip
} from 'mdb-react-ui-kit';
import TagsInput from 'react-tagsinput';
import { ApiClient } from '../../api_client';
import './form_builder.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { getUserId, updateUserTokens } from "../../store/user.reducer";
import { useTranslation } from 'react-i18next';

const FormBuilder = (props: any) => {

  const userId = useSelector(getUserId);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formData, setFormData]: [any, any] = useState({});
  const [topic, setTopic]: [any, any] = useState('');
  const [pendingRequest, setPendingRequest]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const toggleShow = () => setShowAdvancedSettings(!showAdvancedSettings);
  const postsInputs: any = POST_INPUTS;

  useEffect(() => {
    // Reset form data upon topic change
    setFormData((data: any) => {
      const {mainFields, ...cleanedData} = data;
      return {
        ...cleanedData,
        topic
      }
    });
  }, [topic]);


  useEffect(() => {
    setFormData((data: any) => ({
        ...data,
        socialAccounts: props.socialAccounts,
      })
    )
  }, [props.socialAccounts]);

  const updateFormData = (name: string, value: any, fieldGroup: string, isSensitive: boolean = false) => {
    // If value is empty, completely remove the field from formData state
    setFormData((data: any) => {
      if (value === '') {
        delete data[fieldGroup][name]
        return {...data};
      } else {
        return {
          ...data,
          [fieldGroup]: {
            ...data[fieldGroup],
            [name]: {
              value,
              isSensitive
            }
          }
        }
      }
    })
  }

  const handleInputChange = (event: any, fieldGroup: string, isSensitive: boolean = false) => {
    const {name, value} = event.target;
    updateFormData(name, value, fieldGroup, isSensitive);
  };

  const handleCheckboxChange = (event: any, fieldGroup: string) => {
    const {name} = event.target;
    const value = formData[fieldGroup] && formData[fieldGroup][name] === 'true' ? 'false' : 'true';
    updateFormData(name, value, fieldGroup);
  };

  const handleTagsChange = (value: string[], name: string, fieldGroup: string) => {
    updateFormData(name, value, fieldGroup);
  }

  const handleSubmit = (event: any) => {
    // TODO Refactor this

    const {topic, socialAccounts, mainFields, advancedFields} = formData;
    props.pendingRequest(true);
    setPendingRequest(true);
    ApiClient.generatePost({
      user: userId,
      topic,
      socialAccounts: Object.keys(socialAccounts).filter(key => socialAccounts[key]),
      ...mainFields,
      ...advancedFields
    }).then((response: any) => {
      if (response.data?.post == null) {
        handlePostGenerationError(null);
      }
      dispatch(updateUserTokens({
        tokens: response?.data?.remainingTokens,
      }))
      props.updateGeneratedMessage(response?.data);
    })
      .catch((error) => {
        const data = error.response?.data;
        handlePostGenerationError(data.errorCode);
      })
      .finally(() => {
        props.pendingRequest(false);
        setPendingRequest(false);
      });
    event.preventDefault();
  };

  const handlePostGenerationError = (errorCode: string | null) => {
    let errorMessage = '';
    switch (errorCode) {
      case 'TRIAL_EXPIRED':
        errorMessage = t('shared.errors.trialExpired');
        break;
      case 'NO_MORE_TOKENS':
        errorMessage = t('shared.errors.tokensExpired');
        break;
      case 'MEMBERSHIP_EXPIRED':
        errorMessage = t('shared.errors.membershipExpired');
        break;
      default:
        errorMessage = t('shared.errors.unexpected');
    }
    toast.error(errorMessage);
  }

  const InputTypes = {
    text: 'text',
    select: 'select',
    date: 'date',
    tag: 'tag',
    number: 'number',
    checkboxList: 'checkboxList',
    range: 'range',
  };

  const dropdownBuilder = (data: any, fieldName: string, fieldGroup: string) => {

    return (
      <select className="w-100" name={fieldName} defaultValue="" key="1"
              onChange={(event) => handleInputChange(event, fieldGroup)}>
        <option value="" disabled> {t(`formBuilder.${fieldName}`)} </option>
        {
          data.list.map((option: any, index: number) => {
            return <option value={option.value} key={index}>{t(`formBuilder.${option.value}`)}</option>
          })
        }
      </select>
    )
  }

  const checkboxListBuilder = (value: any, data: any, fieldGroup: string) => {
    return (
      data.list.map((checkbox: any, index: number) => {
        return <MDBCheckbox
          key={index}
          label={t(`formBuilder.${checkbox.name}`)}
          name={checkbox.name}
          id={`${data.id}-${checkbox.name}`}
          onChange={(event) => handleCheckboxChange(event, fieldGroup)}
          value={value || ''}
        />
      })
    )
  }

  const sortTopics = (topics: any) => {
    return Object.keys(topics)
      .sort((topicA, topicB) => topics[topicA].placeholder.localeCompare(topics[topicB].placeholder))
      .reduce((sorted: any, topicKey) => {
        sorted[topicKey] = topics[topicKey];
        return sorted;
      }, {});
  }

  const renderTopicsList = () => {
    const sortedTopics = sortTopics(POST_INPUTS.mainFields);

    return (
      <select className="w-100" defaultValue="-" key="topic-selector" onChange={(e) => setTopic(e.target.value)}>
        <option value="-" disabled>
          {t('newPost.chooseTopic')}
        </option>
        {
          Object.keys(sortedTopics).map((option: string, index: number) => {
            return <option value={option} key={index}>{t(`formBuilder.${option}`)}</option>
          })
        }
      </select>
    )
  }

  const fieldsCreator = (fields: any, fieldGroup: string) => {
    return Object.entries(fields).map(([fieldName, fieldData]: [any, any]) => {
      const {type, length, sensitive} = fieldData;
      const placeholder = t(`formBuilder.${fieldName}`);

      const inputId = `${topic}_${fieldName}`;
      let render;
      const value = formData[fieldGroup] && formData[fieldGroup][fieldName] ? formData[fieldGroup][fieldName].value : '';
      switch (type) {
        case InputTypes.text:
          render = (
            <div className={`position-relative ${sensitive ? 'private-field' : ''}`}>
              <MDBInput
                className={`${sensitive ? 'pe-5' : ''}`}
                key={inputId}
                label={placeholder}
                id={inputId}
                name={fieldName}
                type="text"
                maxLength={length}
                onChange={(event) => handleInputChange(event, fieldGroup, sensitive)}
                value={value || ''}
              />
              {(sensitive &&
                <MDBTooltip tag="div" placement="top" title={t('formBuilder.securedField')}>
                  <MDBIcon fas icon="lock"/>
                </MDBTooltip>)}
            </div>
          );
          break;
        case InputTypes.select:
          render = dropdownBuilder(fieldData, fieldName, fieldGroup)
          break;
        case InputTypes.checkboxList:
          render = checkboxListBuilder(value, fieldData, fieldGroup)
          break;
        case InputTypes.date:
          render = (
            <MDBInput
              key={inputId}
              label={placeholder}
              id={inputId}
              name={fieldName}
              type="date"
              onChange={(event) => handleInputChange(event, fieldGroup)}
              value={value || ''}
            />
          );
          break;
        case InputTypes.tag:
          const tags = formData[fieldGroup] && formData[fieldGroup][fieldName] ? formData[fieldGroup][fieldName].value : [];
          render = (
            <div className={`position-relative ${sensitive ? 'private-field' : ''}`}>

              <TagsInput value={tags} onChange={(tags: string[]) => {
                handleTagsChange(tags, fieldName, fieldGroup)
              }} inputProps={{placeholder}}/>
              {(sensitive &&
                <MDBTooltip tag="div" placement="top" title="This field will not be shared with our AI partners.">
                  <MDBIcon fas icon="lock"/>
                </MDBTooltip>)}
            </div>
          );
          break;
        case InputTypes.number:
          render = (
            <MDBInput
              key={inputId}
              label={placeholder}
              id={inputId}
              name={fieldName}
              type="number"
              maxLength={length}
              onChange={(event) => handleInputChange(event, fieldGroup)}
              value={value || ''}
            />
          );
          break;
        case InputTypes.range:
          render = (
            <div className="range-wrapper mb-4">
              <span className="low-label">{t(`formBuilder.min`)}</span>
              <span className="mid-label">{t(`formBuilder.mid`)}</span>
              <span className="high-label">{t(`formBuilder.max`)}</span>
              <MDBRange
                onChange={(event) => handleInputChange(event, fieldGroup)}
                key={inputId}
                label={placeholder}
                id={inputId}
                name={fieldName}
                min={fieldData.min}
                max={fieldData.max}
                defaultValue={fieldData.defaultValue}
                step={fieldData.step}
                disableTooltip={true}
              />
            </div>
          );
          break;
        default:
          return null;
      }

      return (
        <MDBCol className="mt-4" key={fieldName}>
          {render}
        </MDBCol>
      );
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <MDBRow className="row-cols-sm-2 mt-4">
          <MDBCol>
            {renderTopicsList()}
          </MDBCol>
          <MDBCol>
            <select className="w-100" name="outputPostLanguage" defaultValue=""
                    onChange={(event) => handleInputChange(event, 'advancedFields')}>
              <option value="" disabled>
                {t('newPost.chooseLanguage')}
              </option>
              <option value="english">{t(`shared.english`)}</option>
              <option value="french">{t(`shared.french`)}</option>
            </select>
          </MDBCol>
        </MDBRow>
        {
          topic &&
          <>
            <MDBRow className="row-cols-sm-2">
              {fieldsCreator(postsInputs.mainFields[topic], 'mainFields')}
            </MDBRow>

            <div className="more-settings mt-4" onClick={toggleShow}>
              <MDBBtn size="sm" type="button" color="tertiary" outline className="py-2 bg-white">
                {t('newPost.advancedSettings')}
              </MDBBtn>
            </div>

            <MDBCollapse show={showAdvancedSettings} className="h-auto">
              <MDBRow className="row-cols-sm-2">
                {fieldsCreator(postsInputs.advancedFields, 'advancedFields')}
              </MDBRow>
            </MDBCollapse>
          </>
        }
        <MDBRow className="row-cols-sm-2 mt-4">
          <MDBCol>
            <MDBBtn id="generate-post" className="btn-with-loader mx-auto w-100 fw-bold text-center" size="lg" color="primary"
                    disabled={pendingRequest || !topic || Object.keys(props.socialAccounts).filter(key => props.socialAccounts[key]).length === 0}>
              {pendingRequest ? t('newPost.buttonPendingGeneratePost') : t('newPost.buttonGeneratePost')}
              {pendingRequest &&
                <div className="loader-icon fs-5">
                  <MDBIcon fas icon="circle-notch"/>
                </div>
              }
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </form>
    </>
  );
};

export default FormBuilder;
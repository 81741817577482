import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

declare namespace fb {
  interface AuthResponse {
    accessToken: string;
    userID: string;
    expiresIn: number;
    signedRequest: string;
  }

  interface StatusResponse {
    status: string;
    authResponse: AuthResponse;
  }
}

interface InstagramLoginButtonProps {
  loginCallback: (response: any) => void; // Specify the callback function type
}

const InstagramLoginButton: React.FC<InstagramLoginButtonProps> = ({loginCallback}: any) => {
  const [accountsModalVisibility, setAccountsModalVisibility] = useState(false);
  const [igAccounts, setIgAccounts]: any = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    // Initialize the Facebook SDK
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID, // Replace with your Facebook app ID
        cookie: true,
        xfbml: true,
        version: 'v20.0', // Use the latest version available
      });
    };
  }, []);

  const handleFBLogin = () => {
    window.FB.login(
      (response: fb.StatusResponse) => {
        if (response.authResponse) {
          window.FB.api('/me/accounts', {fields: 'instagram_business_account,access_token'}, (pagesResponse: any) => {
            console.log(pagesResponse);
            pagesResponse.data.forEach((account: any) => {
              const instagramAccountId = account.instagram_business_account?.id;
              const accountsData: any = [];

              if (instagramAccountId) {
                // Step 2: Get the Instagram Business Account details
                window.FB.api(
                  `/${instagramAccountId}`,
                  { fields: 'id,name,username,profile_picture_url' },
                  (igResponse: any) => {
                    // Add the retrieved data to accountsData array
                    accountsData.push({
                      id: igResponse.id,
                      name: igResponse.name,
                      username: igResponse.username,
                      profilePictureUrl: igResponse.profile_picture_url,
                      token: account.access_token
                    });

                    // Update state with the new array
                    setIgAccounts([...accountsData]);
                  }
                );
              }
            });
            setAccountsModalVisibility(true);
          });
        }
      },
      {
        scope: 'instagram_basic,pages_show_list,instagram_content_publish,business_management',
        config_id: process.env.REACT_APP_FACEBOOK_CONFIG_ID
      }
    );
  };

  const igAccountsSelectionModal = (igAccounts: any) => {
    let pagesRenderer = [];
    if (igAccounts.length > 0) {
      pagesRenderer.push(
        <p className="text-start">
          {t('socialNetworks.igAccountsSelection.description')}
        </p>
      );
      pagesRenderer.push(igAccounts.map((account: any) => {
        return (<div className="text-start fb-page-selection px-3 py-3"
                     key={account.id}
                     onClick={() => {
                       setAccountsModalVisibility(false);
                       loginCallback({
                         ...account
                       });
                     }}>
          {account.name} - {account.username}
        </div>)
      }));
    } else {
      pagesRenderer.push(<span>{t('socialNetworks.igAccountsSelection.noProPageFound')}</span>);
    }

    return (
      <MDBModal tabIndex="-1" show={accountsModalVisibility} setShow={setAccountsModalVisibility}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {t('socialNetworks.igAccountsSelection.title')}
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              {pagesRenderer}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    )
  }

  return (
    <>
      <MDBBtn className="mt-4 fw-bold w-100" color="primary" rippleColor="light" size="lg" outline rounded
              onClick={handleFBLogin}
      >
        {t('shared.connect')}
      </MDBBtn>
      {igAccountsSelectionModal(igAccounts)}
    </>
  );
};

export default InstagramLoginButton;

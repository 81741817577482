import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import './facebook_login_button.css';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

declare namespace fb {
  interface AuthResponse {
    accessToken: string;
    userID: string;
    expiresIn: number;
    signedRequest: string;
  }

  interface StatusResponse {
    status: string;
    authResponse: AuthResponse;
  }
}

interface FacebookLoginButtonProps {
  loginCallback: (response: any) => void; // Specify the callback function type
}

const FacebookLoginButton: React.FC<FacebookLoginButtonProps> = ({loginCallback}: any) => {
  const [pageModalVisibility, setPageModalVisibility] = useState(false);
  const [userPages, setUserPages] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    // Initialize the Facebook SDK
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID, // Replace with your Facebook app ID
        cookie: true,
        xfbml: true,
        version: 'v20.0', // Use the latest version available
      });
    };
  }, []);

  const handleFBLogin = () => {
    window.FB.login(
      (response: fb.StatusResponse) => {
        if (response.authResponse) {
          window.FB.api('/me/accounts', {fields: 'id,name,category,access_token,picture.width(200).height(200)'}, (pagesResponse: any) => {
            setPageModalVisibility(true);
            setUserPages(pagesResponse.data);
          });
        }
      },
      {
        scope: 'public_profile',
        config_id: process.env.REACT_APP_FACEBOOK_CONFIG_ID
      }
    );
  };

  const pageSelectionModal = (fbPages: any) => {
    let pagesRenderer = [];
    if (fbPages.length > 0) {
      pagesRenderer.push(
        <p className="text-start">
          {t('socialNetworks.facebookPageSelection.description')}
        </p>
      );
      pagesRenderer.push(fbPages.map((page: any) => {
        return (<div className="text-start fb-page-selection px-3 py-3"
                     key={page.id}
                     onClick={() => {
                       setPageModalVisibility(false);
                       loginCallback({
                         ...page
                       });
                     }}>
          {page.name} - {page.category}
        </div>)
      }));
    } else {
      pagesRenderer.push(<span>{t('socialNetworks.facebookPageSelection.noProPageFound')}</span>);
    }

    return (
      <MDBModal tabIndex="-1" show={pageModalVisibility} setShow={setPageModalVisibility}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {t('socialNetworks.facebookPageSelection.title')}
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              {pagesRenderer}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    )
  }

  return (
    <>
      <MDBBtn className="mt-4 fw-bold w-100" color="primary" rippleColor="light" size="lg" outline rounded
              onClick={handleFBLogin}
      >
        {t('shared.connect')}
      </MDBBtn>
      {pageSelectionModal(userPages)}
    </>
  );
};

export default FacebookLoginButton;

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  MDBBtn, MDBCol, MDBFile, MDBIcon, MDBInput, MDBRow,
} from 'mdb-react-ui-kit';
import './message_attachments.css';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import 'canvas-editor/dist/index.css'
// @ts-ignore
import CanvasEditor from 'canvas-editor/dist';
import { useSelector } from 'react-redux';
import { getUserId } from '../../store/user.reducer';
import { IS_IMAGE, IS_VIDEO } from '../../constants';
import { getSelectedClient } from '../../store/manager.reducer';

const MessageAttachments = forwardRef((props: any, ref: any) => {

  const {t} = useTranslation();
  const userId = useSelector(getUserId);
  const selectedClient = useSelector(getSelectedClient);

  const [attachmentFormat, setAttachmentFormat]: [any, any] = useState(null);
  const [attachmentFiles, setAttachmentFiles]: [any, any] = useState([]);
  const [imageGeneratorInput, setImageGeneratorInput]: [any, any] = useState(null);
  const [imageSetupInput, setImageSetupInput]: [any, any] = useState(null);
  const [imageSource, setImageSource]: [any, any] = useState();
  const [videoSource, setVideoSource]: [any, any] = useState(null);
  const [pendingImageGeneration, setPendingImageGeneration]: [any, any] = useState(false);
  const [attachmentSetupLayer, setAttachmentSetupLayer]: [any, any] = useState(false);
  const [generatedTemplate, setGeneratedTemplate]: [any, any] = useState(null);

  const videoRef = useRef<HTMLVideoElement>(null);
  const fileAttachmentRef = useRef<any>(null);

  // Expose the clearFileAttachment function to the parent component
  useImperativeHandle(ref, () => ({
    clearFileAttachment,
  }));

  useEffect(() => {
    setImageGeneratorInput(null);
    setImageSource(null);
    if (props.messageParameters) {
      if (props.messageParameters.post && props.messageParameters.title) {
        setImageSetupInput({
          title: props.messageParameters.title,
          subtitle: props.messageParameters.subtitle,
        });

        // TODO Generate the template image only when needed
        regenImage();
      }

      if (props.messageParameters?.attachment?.length > 0) {
        const file = props.messageParameters?.attachment[0];
        if (IS_VIDEO(file.name)) {
          setAttachmentFormat('video');
          setVideoSource(`${process.env.REACT_APP_ASSETS_CDN}/${selectedClient}/${props.messageParameters.attachment[0].name}`);
          setAttachmentFiles([file]);
        } else if (IS_IMAGE(file.name)) {
          setAttachmentFormat('image');
          setImageSource(`${process.env.REACT_APP_ASSETS_CDN}/${selectedClient}/${props.messageParameters.attachment[0].name}`);
          setAttachmentFiles([file]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.messageParameters, userId]);

  useEffect(() => {
    props.setAttachmentFiles(attachmentFiles);
    videoRef?.current?.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentFiles]);

  const randomValue = () => Math.floor(Math.random() * 256);

  const regenImage = (title: any = null, subtitle: any = null, template: any = null) => {
    setPendingImageGeneration(true);
    const imageData = {
      type: 'standard',
      field: props?.messageParameters?.field,
      content: {
        title: title || props?.messageParameters?.title,
        subtitle: subtitle || props?.messageParameters?.subtitle,
        primaryColor: `rgba(${randomValue()},${randomValue()},${randomValue()},1)`,
        secondaryColor: `rgba(${randomValue()},${randomValue()},${randomValue()},0.16)`
      },
      providedTemplate: template
    }

    setImageGeneratorInput(imageData);
  }

  const handleAttachmentFileChange = (event: any) => {
    const file = event.target.files[0];

    if (IS_VIDEO(file.name)) {
      setAttachmentFormat('video');
      setVideoSource(URL.createObjectURL(file));
      setAttachmentFiles([file]);
    } else if (IS_IMAGE(file.name)) {
      console.log(file)
      setAttachmentFormat('image');
      setImageSource(URL.createObjectURL(file));
      setAttachmentFiles([file]);
    } else {
      toast.error(t('postPreview.errors.fileFormat'));
      clearFileAttachment();
    }
  }

  const base64ToFile = (base64String: string) => {
    // Split the base64 string into data and content type
    const arr = base64String.split(',');
    // @ts-ignore
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // Convert binary string to an array buffer
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    // Create a File object from the Uint8Array (array buffer)
    return new File([u8arr], 'generated-image.jpeg', {type: mime});
  }

  const clearFileAttachment = () => {
    if (fileAttachmentRef.current) {
      fileAttachmentRef.current.value = null;
    }
    setAttachmentFormat(null);
    setImageSource(null);
    setAttachmentFiles([]);
  }

  return (
    <React.Fragment>
      {
        imageGeneratorInput &&
				<div className="d-none">
					<CanvasEditor headless={true} getCanvasExport={(template: any) => {
            setTimeout(() => {
              setGeneratedTemplate(template);
              setImageSource(template.encodedImage);
              setAttachmentFormat('image');
              setPendingImageGeneration(false);
              setAttachmentFiles([base64ToFile(template.encodedImage)]);
            }, 1000);
          }} inputParams={imageGeneratorInput}/>
				</div>
      }

      {
        attachmentFiles?.length > 0 &&
				<div id="attachment-preview">
          {
            attachmentSetupLayer &&
						<div id="attachment-setup">
							<h4>Image setup</h4>
							<MDBInput className="mt-4" label="Title" value={imageSetupInput?.title}
							          onChange={(e) => {
                          setImageSetupInput({
                            ...imageSetupInput,
                            title: e.target.value,
                          });
                        }}/>
							<MDBInput className="mt-3" label="Subtitle" value={imageSetupInput?.subtitle}
							          onChange={(e) => {
                          setImageSetupInput({
                            ...imageSetupInput,
                            subtitle: e.target.value,
                          });
                        }}
							/>
              
							<MDBBtn className="mt-3" color="primary"
							        onClick={() => {
                        regenImage(imageSetupInput?.title,
                          imageSetupInput?.subtitle,
                          {
                            elements: generatedTemplate.elements,
                            params: generatedTemplate.params
                          }
                        );
                        setAttachmentSetupLayer(false);
                      }}
							>Apply</MDBBtn>
						</div>
          }

          {
            attachmentFormat === 'image' &&
						<img src={imageSource} onError={() => {
              clearFileAttachment();
              toast.error(t('postPreview.errors.fileFormat'));
            }} alt=""/>
          }
          {
            videoSource &&
            (
              attachmentFormat === 'video' &&
							<video ref={videoRef} className="w-100" controls>
								<source src={videoSource} type="video/mp4"/>
							</video>
            )
          }

					<div className="attachment-btn-wrapper">
						<MDBBtn className="remove-attachment fs-6 shadow-custom me-2" color="secondary"
						        onClick={() => setAttachmentSetupLayer(true)}>
							<MDBIcon fas icon="pen"/>
						</MDBBtn>
						<MDBBtn className="remove-attachment fs-6 shadow-custom" color="secondary"
						        onClick={() => clearFileAttachment()}>
							<MDBIcon fas icon="trash"/>
						</MDBBtn>
					</div>
				</div>
      }
      <MDBRow>
        <MDBCol className="col col-10 pe-0">
          <MDBFile inputRef={fileAttachmentRef} id="attachment-file" onChange={handleAttachmentFileChange}
                   accept="image/*,video/*"
                   className="fs-7"
          />
        </MDBCol>
        <MDBCol className="col col-2 ps-0">
          {props.children}
        </MDBCol>
      </MDBRow>
      {
        props.messageParameters?.title &&
				<MDBBtn className="btn-with-loader mt-3" onClick={() => regenImage(imageSetupInput?.title, imageSetupInput?.subtitle)} disabled={pendingImageGeneration}>
					Regenerate Image
          {pendingImageGeneration &&
						<div className="loader-icon fs-5">
							<MDBIcon fas icon="circle-notch"/>
						</div>
          }
				</MDBBtn>
      }
    </React.Fragment>
  )

});

export default MessageAttachments;
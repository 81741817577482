import React from 'react';
import {UserSocialMedia} from '../../store/user.reducer';
import './post_header.css';
import { formatName } from '../../constants';

const PostHeader = ({socialMediaAccount}: { socialMediaAccount: UserSocialMedia }) => {

  return (
    <div id="post-header">
      <img className="me-2" src={`${socialMediaAccount.avatar}`} onError={(e: any) => {
        console.log('error', e)
        e.target.src = `${process.env.PUBLIC_URL}/assets/images/avatar.png`;
      }}
           alt=""/>
      <div className="social-profile-info">
        <span className="profile-name m-0 fw-bold">
          {formatName(socialMediaAccount.firstName, socialMediaAccount.lastName)}
        </span>
        <span className="profile-description m-0">
          { socialMediaAccount.headline || '-' }
        </span>
      </div>
    </div>)
}

export default PostHeader;

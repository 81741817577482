import React, { useState } from 'react';
import './sign_up.css';

import { Amplify, Auth } from 'aws-amplify';
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import { user_pool_conf } from '../shared/constants';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';

Amplify.configure(user_pool_conf);

export default function SignUp() {
  const stages = ['signUp', 'confirmationSent'];

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pendingRequest, setPendingRequest] = useState(false);
  const [stage, setStage] = useState(stages[0]);

  const {t} = useTranslation();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setPendingRequest(true);
    Auth.signUp({
      username: email,
      password: password,
    }).then(response => {
      setStage(stages[1]);
    }).catch(exception => {
      console.log(exception);
      toast.error(t('signUp.error'));
    }).finally(() => {
      setPendingRequest(false);
    })
  };

  return (
    <div className="sign-up-page">
      <div className="d-flex h-100">
        <MDBRow className="align-self-center w-100 h-100 m-0">
          <MDBCol md="8" className="left d-flex text-center p-0">
            <div className="side-view d-flex w-100 align-items-center">
              <Link to="/" className="m-auto">
                <img src={`${process.env.PUBLIC_URL}/assets/images/feedler_white_logo.png`} alt=""/>
              </Link>
            </div>
          </MDBCol>
          <MDBCol md="4" className="right bg-white">
            <form className="sign-up-form" onSubmit={handleSubmit}>
              {stage === stages[0] &&
                <>
                  <h2>
                    {t('signUp.title')}
                  </h2>
                  <MDBInput className="mb-3" label={t('shared.Email')} value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            required/>
                  <MDBInput className="mb-3" label={t('shared.Password')} type="password" value={password}
                            onChange={(event) => setPassword(event.target.value)} required/>
                  <MDBBtn className="btn-with-loader w-100" size="lg" color="primary" type="submit" rounded
                          disabled={pendingRequest}>
                    {pendingRequest ? t('signUp.buttonPendingLabel') : t('signUp.buttonLabel')}
                    {pendingRequest &&
                      <div className="loader-icon fs-5">
                        <MDBIcon fas icon="circle-notch"/>
                      </div>
                    }
                  </MDBBtn>
                  <Link to="/sign-in" className="mt-3 w-100 d-inline-block text-center fs-7">
                    {t('shared.alreadySignedUp')}
                  </Link>
                </>
              }
              {stage === stages[1] &&
                <div className="fs-5 text-center w-space-pre-line">
                  <Trans i18nKey="signUp.success">
                    Fantastic! 🚀
                    You've successfully signed up.
                    <span className="fs-3">✉️</span>
                    To complete your registration, kindly click the confirmation link we've sent to your email. Let the
                    adventure begin!
                  </Trans>
                </div>
              }
            </form>

          </MDBCol>
        </MDBRow>
      </div>
    </div>
  );
}

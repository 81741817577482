import React, { useEffect, useState } from 'react';
import './post.css';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardText,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow
} from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkedinAccount, getUserId, getUserRole, UserSocialMedia } from '../../store/user.reducer';
import PostHeader from '../post_header/post_header';
import { IS_IMAGE, IS_VIDEO, SOCIAL_ACCOUNTS } from '../../constants';
import { toast } from 'react-toastify';
import { ApiClient } from '../../api_client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toggleMainLoader } from '../../store/app.reducer';
import { getSelectedClient } from '../../store/manager.reducer';

const Post = ({
                postData,
                hideControls,
                deletionCallback
              }: { postData: ScheduledMessage, hideControls?: boolean, deletionCallback: Function }) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector(getUserId);
  const [deletionModalVisibility, setDeletionModalVisibility] = useState(false);
  const [managedUser, setManagedUser]: any = useState();

  const linkedinAccount: UserSocialMedia = useSelector(getLinkedinAccount);
  const userRole = useSelector(getUserRole);
  const selectedClient = useSelector(getSelectedClient);

  useEffect(() => {
    if (userRole === 'manager' && selectedClient) {
      setManagedUser(selectedClient);
    } else if (userId) {
      setManagedUser(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, userId]);

  const messageFormatter = (message: string) => {
    const parts = message.split(' ');
    let result: any[] = [];
    parts.forEach(part => {
      if (part.startsWith('#')) {
        result.push(<a href={`https://www.linkedin.com/feed/hashtag/?keywords=${part.substring(1)}`} className="fw-bold"
                       target="_blank"
                       rel="noopener noreferrer" key={Math.random()}>{part}</a>);
      } else if (part.startsWith('http://') || part.startsWith('https://')) {
        result.push(<a href={part} className="fw-bold" target="_blank" rel="noopener noreferrer"
                       key={Math.random()}>{part}</a>);
      } else {
        const formattedPart = part.split('\\n').map((line, index) => (
          <React.Fragment key={index}>
            {line === '' ? <br/> : line}
          </React.Fragment>
        ));
        result.push(formattedPart);
      }
      result.push(' ');
    });
    return result;
  };

  const formatPostDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} at ${date.toTimeString().slice(0, 5)}`
  }

  const renderPostStatus = (status: string) => {
    switch (status) {
      case 'published':
        return (<span className="post-status"><MDBIcon far icon="check-circle"/> {t('post.published')}</span>);
      case 'scheduled':
        return (<span className="post-status"><MDBIcon far icon="calendars-alt"/> {t('post.scheduled')}</span>);
      case 'error':
        return (<span className="post-status"><MDBIcon fas icon="exclamation-circle"/> {t('post.error')}</span>);
      default:
        return;
    }
  }

  const providerRenderer = (providers: string[]) => {
    const renderer: any = [];
    type keysType = keyof typeof SOCIAL_ACCOUNTS;
    providers.forEach((provider: string) => {
      const icon = SOCIAL_ACCOUNTS[provider as keysType].icon;
      const iconColor = SOCIAL_ACCOUNTS[provider as keysType].iconColor;
      renderer.push(
        <div className="published-on text-center mx-1 p-0" key={provider}>
          <MDBIcon fab icon={icon} size="1x" style={{color: iconColor}}/>
        </div>
      )
    })
    return renderer;
  }

  const modalPostDeletion = (messageId: string) => {
    return (
      <>
        <MDBModal tabIndex="-1" show={deletionModalVisibility} setShow={setDeletionModalVisibility}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  {t('post.deletePostModal.title')}
                </MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={() => setDeletionModalVisibility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p>
                  {t('post.deletePostModal.content')}
                </p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={() => setDeletionModalVisibility(false)}>
                  {t('shared.cancel')}
                </MDBBtn>
                <MDBBtn color="danger" onClick={() => deletePost(messageId)}>
                  {t('post.deletePost')}
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    )
  }

  const deletePost = (messageId: string) => {
    setDeletionModalVisibility(false);
    dispatch(toggleMainLoader({
      isVisible: true
    }));
    ApiClient.deletePostById(userId, managedUser, messageId)
      .then(() => {
        toast.success(t('post.postDeleted'));
        if (typeof deletionCallback === 'function') {
          deletionCallback();
        }
      })
      .catch(() => {
        toast.error(t('shared.errors.unexpected'));
      })
      .finally(() => {
        dispatch(toggleMainLoader({
          isVisible: false
        }));
      })
  }

  const renderAttachment = () => {
    if (postData?.attachment?.length > 0) {
      const attachment: Attachment | undefined = postData.attachment[0];
      if (IS_IMAGE(attachment?.name)) {
        return (
          <div
            className="attachment-image"
            style={{backgroundImage: `url(${process.env.REACT_APP_ASSETS_CDN}/${managedUser}/${attachment?.name})`}}
          />
        )
      }
      if (IS_VIDEO(attachment?.name) && managedUser) {
        return (
          <video className="w-100" controls>
            <source src={`${process.env.REACT_APP_ASSETS_CDN}/${managedUser}/${attachment?.name}`} type="video/mp4"/>
          </video>
        )
      }
      return '';
    }
  };

  return (
    <MDBCard key={postData.messageId} className={`full-post ${postData.status}`}>
      <MDBCardBody>
        <MDBRow>
          <MDBCol className="col-10">
            <PostHeader socialMediaAccount={linkedinAccount}/>
          </MDBCol>
          <MDBCol className="col-2 ps-0 py-2 mb-3 text-end">
            {providerRenderer(postData.providers)}
          </MDBCol>
        </MDBRow>

        <MDBCardText className="message-wrapper">
          {messageFormatter(postData.message)}
        </MDBCardText>
      </MDBCardBody>

      {renderAttachment()}

      <MDBCardFooter className="py-0 pe-0 overflow-hidden">
        <MDBRow>
          <MDBCol className="py-2 card-footer-left">
            <MDBRow>
              <MDBCol>
                {renderPostStatus(postData.status)}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <small className="text-muted">{formatPostDate(postData.publicationTimestamp * 1000)}</small>
              </MDBCol>
            </MDBRow>
            <MDBRow>
            </MDBRow>
          </MDBCol>
          {(modalPostDeletion(postData.messageId))}

          {(!hideControls && postData.messageId &&
            <MDBCol className="text-end px-0 flex-grow-0">
              <MDBBtn color="tertiary" className="h-100 card-btn delete"
                      onClick={() => setDeletionModalVisibility(!deletionModalVisibility)}>
                <MDBIcon far icon="trash-alt"/>
                <span className="d-block">
                  {t('post.delete')}
                </span>
              </MDBBtn>
            </MDBCol>
          )}
          {!hideControls &&
            <MDBCol className="text-end ps-0 flex-grow-0">
              <MDBBtn color="tertiary" className="h-100 card-btn"
                      onClick={() => {
                        navigate('/app/new-post', {
                          state: {
                            message: postData.message,
                            comments: postData.comments,
                            attachment: postData.attachment
                          }
                        })
                      }}
              >
                <MDBIcon far icon="paper-plane"/>
                <span className="d-block">
                {t('post.repost')}
              </span>
              </MDBBtn>
            </MDBCol>
          }

        </MDBRow>
      </MDBCardFooter>
    </MDBCard>
  )


};

export default Post;

export interface ScheduledMessage {
  message: string;
  messageId: string;
  providers: string[];
  publicationTimestamp: number;
  user: string;
  status: string;
  attachment: any,
  comments: string[]
}

export interface Attachment {
  name: string;
  type: string;
  size: number;
}
import React, { useState } from 'react';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
} from 'mdb-react-ui-kit';
import './account_settings.css';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import SocialMediaSettings from '../shared/parts/social_media_settings/social_media_settings';
import GeneralSettings from './general_settings/general_settings';
import BillingSettings from './billing_settings/billing_settings';
import { useTranslation } from 'react-i18next';

const AccountSettings = () => {

  const {t} = useTranslation();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const changePassword = async () => {
    const user = await Auth.currentAuthenticatedUser();
    Auth.changePassword(user, oldPassword, newPassword)
      .then(() => {
        toast.success(t('shared.passwordUpdated'));
      }).catch(err => {
      toast.error(t('shared.passwordRules'));
    });
  }

  return (
    <MDBContainer>

      <MDBRow className="row-cols-sm-12 m-0">
        <MDBCol>
          <MDBCardTitle className="fs-2 my-4">
            {t('settings.title')}
          </MDBCardTitle>
        </MDBCol>
      </MDBRow>
      <MDBRow className="m-0">
        <MDBCol className="col-12 col-lg-6 mb-4">
          <BillingSettings/>
          <SocialMediaSettings/>
        </MDBCol>

        <MDBCol className="col-12 col-lg-6">
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBRow className="row-cols-sm-12 mb-3">
                <MDBCardTitle className="mt-0 fs-4">
                  {t('shared.Password')}
                </MDBCardTitle>
              </MDBRow>

              <MDBInput wrapperClass="d-none" type="password"/>

              <MDBInput className="mb-3" label={t('shared.currentPassword')} value={oldPassword}
                        onChange={(event) => setOldPassword(event.target.value)}
                        type={showOldPassword ? 'text' : 'password'} autoComplete="off">
                <MDBIcon far icon={showOldPassword ? 'eye-slash' : 'eye'} className="input-icon"
                         onClick={() => setShowOldPassword(!showOldPassword)}/>
              </MDBInput>

              <MDBInput className="mb-3" label={t('shared.newPassword')} value={newPassword}
                        onChange={(event) => setNewPassword(event.target.value)}
                        type={showNewPassword ? 'text' : 'password'}>
                <MDBIcon far icon={showNewPassword ? 'eye-slash' : 'eye'} className="input-icon"
                         onClick={() => setShowNewPassword(!showNewPassword)}/>
              </MDBInput>
              <MDBRow className="row-cols-sm-12 text-end mt-4">
                <MDBCol>
                  <MDBBtn color="primary" size="lg" onClick={() => changePassword()}
                          disabled={newPassword === '' || oldPassword === '' || oldPassword !== newPassword}>
                    {t('settings.updatePassword')}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          <GeneralSettings/>

        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default AccountSettings;
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  MDBCard, MDBCardBody, MDBCardTitle, MDBCol,
  MDBContainer,
  MDBRow
} from 'mdb-react-ui-kit';
import './new_post.css';
import 'react-tagsinput/react-tagsinput.css'
import FormBuilder from '../shared/parts/form_builder/form_builder';
import { SocialMediaConnect } from '../shared/parts/social_media_connect/social_media_connect';
import MessagePreview from '../shared/parts/message_preview/message_preview';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NewPost = () => {

  const location: any = useLocation();
  const [messageParameters, setMessageParameters]: [any, any] = useState(null);
  const [selectedSocialMedia, setSelectedSocialMedia]: [any, any] = useState({
    linkedin: false,
    facebook: false,
    instagram: false
  });
  const [pendingRequest, setPendingRequest]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)

  const {t} = useTranslation();

  useEffect(() => {
    setMessageParameters({
      post: location?.state?.message || '',
      comments: location?.state?.comments || [],
      attachment: location?.state?.attachment || []
    })
  }, [location?.state?.message, location?.state?.comments, location?.state?.attachment])

  return (
    <MDBContainer>
      <MDBRow className="g-4 m-0">
        <MDBCol className="col-12 col-xl-7">
          <MDBCard className="h-100">
            <MDBCardBody>
              <MDBRow className="row-cols-sm-12">
                <MDBCardTitle className="mt-0 fs-4">
                  {t('newPost.title')}
                </MDBCardTitle>
              </MDBRow>

              <MDBRow className="row-cols-sm-12">
                <SocialMediaConnect selectSocialMedia={setSelectedSocialMedia}/>
              </MDBRow>

              <FormBuilder socialAccounts={selectedSocialMedia}
                           updateGeneratedMessage={setMessageParameters}
                           pendingRequest={setPendingRequest}
              />

            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol className="col-12 col-xl-5">
          <MessagePreview
            selectedSocialMedia={selectedSocialMedia}
            messageParameters={messageParameters}
            pendingRequest={pendingRequest}
          />
        </MDBCol>
      </MDBRow>

    </MDBContainer>
  );
};

export default NewPost;
import { configureStore } from '@reduxjs/toolkit'

import appReducer from "./app.reducer";
import userReducer from './user.reducer';
import managerReducer from './manager.reducer';

const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    manager: managerReducer
  }
})

export default store

import React, { useState, useEffect } from 'react';
import './calendar_post.css';
import Post from '../post/post'; // Add your CSS styles for Tooltip

const CalendarPost = React.forwardRef(({ post }: any, ref: any) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [targetRegion, setTargetRegion] = useState('');

  useEffect(() => {
    document.addEventListener('hideCalendarPosts', onCalendarPostToggle)
    return () => {
      document.removeEventListener('hideCalendarPosts', onCalendarPostToggle)
    }
  }, []);

  const date = new Date(post.publicationTimestamp * 1000);

  const onCalendarPostToggle = (event: any) => {
    setShowTooltip(false);
  }

  const handleClick = (event: any) => {
    const { top, left, height, width } = event.target.getBoundingClientRect();

    const isOnTheRight = left + (width / 2) > window.innerWidth / 2;
    const isAtTheBottom = top + (height / 2) > window.innerHeight / 2;

    setTargetRegion(
      isAtTheBottom
        ? isOnTheRight
          ? 'bottom-right'
          : 'bottom-left'
        : isOnTheRight
          ? 'top-right'
          : 'top-left'
    );
    document.dispatchEvent(new CustomEvent('hideCalendarPosts', {detail: 'bidule'}));
    setShowTooltip(!showTooltip);
  };

  const formatTimeForCalendar = (date: Date) => {
    return date.toTimeString().slice(0, 5);
  }

  return (
    <>
      <div className={`post-summary text-start w-100 fs-7 ${post.status}`} onClick={handleClick}>
        <span className={`short-content ${post.status}`}>
          {`${formatTimeForCalendar(date)} | ${post.message}`}
        </span>

        {showTooltip && (
          <div className={`post-preview ${targetRegion}`}>
            <Post postData={post} deletionCallback={() => {}} hideControls={true} />
          </div>
        )}
      </div>
    </>
  );
});

export default CalendarPost;
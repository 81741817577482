import axios, { AxiosInstance } from 'axios';
import { Auth } from "aws-amplify";

const path = process.env.REACT_APP_API_PATH;

export class ApiClient {

  static api: AxiosInstance;
  static retryCounter = 0;
  static maxRetries = 3;

  static generatePost(payload: any) {
    return ApiClient.api.post(`${path}/posts/generate`, payload);
  }

  static scheduleMessage(payload: any) {
    return ApiClient.api.put(`${path}/posts/schedule`, payload);
  }

  static getScheduledMessages(user: string, from?: number | undefined | null) {
    return ApiClient.api.get(`${path}/posts`, {
      params: {
        user,
        from
      }
    });
  }

  static deletePostById = (user: string, managedUser: string, messageId: string) => {
    return ApiClient.api.delete(`${path}/posts`,
      {
        params: {
          user,
          client: managedUser,
          messageId
        }
      });
  }

  static getSocialMediaAccounts = (user: string) => {
    return ApiClient.api.get(`${path}/socialmedia`,
      {
        params: {
          user
        }
      });
  }

  static storeSocialMediaAccount = (data: any) => {
    return ApiClient.api.put(`${path}/socialmedia`, data);
  }

  static deleteSocialMediaAccount = ({
                                       user,
                                       provider,
                                       externalId
                                     }: { user: string, provider: string, externalId: string }) => {
    return ApiClient.api.delete(`${path}/socialmedia`,
      {
        params: {
          user, provider, externalId
        }
      });
  }

  static getPaymentUrl = (user: string, paymentPlan: string) => {
    return ApiClient.api.get(`${path}/subscription/checkout-url`,
      {
        params: {
          user,
          paymentPlan
        }
      });
  }

  static getUserSubscriptions = (user: string) => {
    return ApiClient.api.get(`${path}/subscription`,
      {
        params: {
          user
        }
      });
  }

  static updateSubscription = (user: string, subscriptionId: string, action: string) => {
    return ApiClient.api.post(`${path}/subscription`,
      {
        user,
        subscriptionId,
        action
      }
    );
  }

  static getUserMetadata = (user: string) => {
    return ApiClient.api.get(`${path}/user`,
      {
        params: {
          user
        }
      });
  }

  static deleteUserAccount = (user: string) => {
    return ApiClient.api.delete(`${path}/user`,
      {
        params: {
          user
        }
      });
  }

  static getManagedClients(user: any) {
    return ApiClient.api.get(`${path}/clients`, {
      params: {
        user
      }
    });
  }

  static inviteClient = ({ email, firstName, lastName, creator }: { email: string, firstName: string, lastName: string, creator: string }) => {
    return ApiClient.api.put(`${path}/clients`, {
      email, firstName, lastName, creator
    });
  }

  static setupClient = () => {
    ApiClient.api = axios.create({
      // Configure your API endpoint here
      baseURL: process.env.REACT_APP_API_PATH,
    });

    // Add request interceptor
    const requestInterceptor = ApiClient.api.interceptors.request.use(
      async (config) => {
        // Get the current session from Amplify
        const session = await Auth.currentSession();

        // Add the access token to the request headers
        config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Add response interceptor
    const responseInterceptor = ApiClient.api.interceptors.response.use(
      (response) => {
        ApiClient.retryCounter = 0;
        return response;
      },
      async (error) => {
        // Check if the error status is 401 (Unauthorized)
        if (error?.response?.status === 401) {
          if (ApiClient.retryCounter < ApiClient.maxRetries) {
            try {
              // Refresh the token using Amplify
              const refreshedSession = await Auth.currentSession();

              // Update the access token in the request headers
              error.config.headers.Authorization = `Bearer ${refreshedSession.getIdToken().getJwtToken()}`;

              ApiClient.retryCounter++;

              // Retry the failed request
              return ApiClient.api(error.config);
            } catch (refreshError) {
              // If token refresh also fails, handle the error as needed
              console.error('Failed to refresh token: ', refreshError);
              // For example, you may redirect the user to the login page
              Auth.signOut().then(() => window.location.reload());
            }
          } else {
            Auth.signOut().then(() => window.location.reload());
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup interceptors when the component unmounts
    return () => {
      ApiClient.api.interceptors.request.eject(requestInterceptor);
      ApiClient.api.interceptors.response.eject(responseInterceptor);
    };
  }

}
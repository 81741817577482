import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  email: '',
  userId: '',
  firstName: '',
  lastName: '',
  role: '',
  bu: '',
  isLogged: false,
  idToken: '',
  facebook: {
    isConnected: false,
    isSelected: false,
    provider: 'facebook'
  },
  linkedin: {
    isConnected: false,
    isSelected: false,
    provider: 'linkedin'
  },
  instagram: {
    isConnected: false,
    isSelected: false,
    provider: 'instagram'
  }
}

export interface UserState {
  email: string,
  userId: string,
  firstName: string,
  lastName: string,
  role: string,
  bu: string,
  accountType?: string,
  tokens?: number,
  latestSubscriptionId?: string | undefined,
  subscriptionAutoRenewal?: boolean | undefined,
  subscriptionEnd?: number,
  isLogged: boolean,
  idToken: string,
  linkedin: UserSocialMedia,
  facebook: UserSocialMedia,
  instagram: UserSocialMedia,
}

export interface UserSocialMedia {
  id?: string | undefined;
  provider: string;
  icon?: string;
  iconColor?: string;
  isConnected: boolean | undefined;
  avatar?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  headline?: string | undefined;
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser(state: UserState, action) {
      state.email = action.payload.email;
      state.userId = action.payload.userId;
      state.isLogged = true;
    },
    updateUserMetadata(state: UserState, action) {
      return {
        ...state,
        ...action.payload
      }
    },
    updateSubscriptionAutoRenewal(state: UserState, action) {
      return {
        ...state,
        ...action.payload
      }
    },
    updateUserTokens(state: UserState, action) {
      return {
        ...state,
        ...action.payload
      }
    },
    connectSocialAccount(state: UserState, action) {
      // @ts-ignore
      state[action.payload.provider] = {
        provider: action.payload.provider,
        isConnected: true,
        ...action.payload
      }
    },
    // TODO Review typing
    disconnectSocialAccount(state: any, action: {payload : {provider: string}}) {
      const provider = action.payload.provider;
      const providerData = state[provider as keyof typeof state] || {};
      return {
        ...state,
        [provider]: {
          ...providerData,
          isSelected: false,
          isConnected: false
        }
      };
    },
    logoutUser(state: UserState) {
      state = initialState;
    }
  }
})

export const {loginUser, updateUserMetadata, updateSubscriptionAutoRenewal, updateUserTokens, connectSocialAccount, disconnectSocialAccount, logoutUser} = userSlice.actions;

export const getUser = ((state: any) => state.user);
export const getLinkedinAccount = ((state: any) => state.user.linkedin);
export const getFacebookAccount = ((state: any) => state.user.facebook);
export const getInstagramAccount = ((state: any) => state.user.instagram);
export const getUserEmail = ((state: any) => state.user.email);
export const getUserId = ((state: any) => state.user.userId);
export const getUserRole = ((state: any) => state.user.role);
export const getSocialAccounts = ((state: any) => ({
  linkedin: state.user.linkedin,
  instagram: state.user.instagram,
  facebook: state.user.facebook
}));

export default userSlice.reducer;

import React, { useEffect, useState } from 'react';
import './reset_password.css';

import { Amplify, Auth } from 'aws-amplify';
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import { user_pool_conf } from '../shared/constants';
import { Link, useNavigate } from 'react-router-dom';

Amplify.configure(user_pool_conf);

const stages = {
  RESET: 'reset',
  VALIDATE_CODE: 'validate_code',
}

export default function ResetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [stage, setStage] = useState(stages.RESET);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('')
  const [pendingRequest, setPendingRequest] = useState(false)

  useEffect(() => {
    setError('');
  }, [stage]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setPendingRequest(true);
    setError('');
    switch (stage) {
      case stages.RESET:
        Auth.forgotPassword(email).then(response => {
          setStage(stages.VALIDATE_CODE);
        }).catch(err => {
          setError('Incorrect email address')
        }).finally(() => {
          setPendingRequest(false);
        })
        break;
      case stages.VALIDATE_CODE:
        Auth.forgotPasswordSubmit(email, verificationCode, newPassword).then(resp => {
          loginWithNewPassword();
        }).catch(err => {
          setError('Password could not be reset, please make sure the verification code is correct and your new password is 8 chars long and it contains at least 1 upper case, 1 lower case, 1 number and 1 special char.')
        }).finally(() => {
          setPendingRequest(false);
        })
        break;
      default:
        setPendingRequest(false);
        return;
    }
  };

  const loginWithNewPassword = () => {
    Auth.signIn({
      username: email,
      password: newPassword,
    }).then(response => {
      // Refresh route
      navigate(0)
    }).catch(error => {
      console.log('Error signing in:', error);
    });
  }

  const renderForms = () => {
    switch (stage) {
      case stages.RESET:
        return (
          <>
            <h2>Reset password</h2>
            <p className="mb-3">Enter your account email address</p>
            <MDBInput className="mb-3" label="Email" value={email} onChange={(event) => setEmail(event.target.value)}
                      required/>
            {error && <p className="text-danger fs-7">{error}</p>}
            <MDBBtn className="btn-with-loader w-100" size='lg' color="primary" type="submit" rounded
                    disabled={pendingRequest}>
              {pendingRequest ? 'Sending' : 'Reset my password'}
              {pendingRequest &&
                <div className="loader-icon fs-5">
                  <MDBIcon fas icon="circle-notch"/>
                </div>
              }
            </MDBBtn>
            <Link to="/sign-in" className="mt-3 w-100 d-inline-block text-center fs-7">Already have an account? Log
              in</Link>
            <Link to="/sign-up" className=" w-100 d-inline-block text-center fs-7">Create an account</Link>
          </>
        )
      case stages.VALIDATE_CODE:
        return (
          <>
            <h2>Reset password</h2>
            <p className="mb-3">An email with a verification code has been sent to the email address:
              <br/>
              <span className="fw-bold">{email}</span>
            </p>
            <p className="mb-3">Enter the verification code below and set a new password</p>
            <MDBInput className="mb-3" label="Verification code" value={verificationCode}
                      onChange={(event) => setVerificationCode(event.target.value)} required/>
            <MDBInput className="mb-3" label="New password" type="password" value={newPassword}
                      onChange={(event) => setNewPassword(event.target.value)} required/>
            {error && <p className="text-danger fs-7">{error}</p>}
            <MDBBtn className="w-100 mb-3" size='lg' color="primary" type="submit" rounded>Submit</MDBBtn>
            <MDBBtn className="w-100" size='lg' color="primary" type="button" rounded
                    onClick={() => setStage(stages.RESET)}>Back</MDBBtn>
          </>
        )
      default:
        return;
    }

  }

  return (
    <div className="sign-in-page">
      <div className="d-flex h-100">
        <MDBRow className="align-self-center w-100 h-100 m-0">
          <MDBCol md="8" className="left d-flex text-center p-0">
            <div className="side-view d-flex w-100 align-items-center">
              <Link to="/" className="m-auto" >
                <img src={`${process.env.PUBLIC_URL}/assets/images/feedler_white_logo.png`} alt=""/>
              </Link>
            </div>
          </MDBCol>
          <MDBCol md="4" className="right bg-white">
            <form className="sign-in-form" onSubmit={handleSubmit}>
              {renderForms()}
            </form>
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import './calendar.css';
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { ApiClient } from '../../api_client';
import { useSelector } from 'react-redux';
import { getUserId, getUserRole } from '../../store/user.reducer';
import { useTranslation } from 'react-i18next';
import CalendarPost from '../calendar_post/calendar_post';
import { getSelectedClient } from '../../store/manager.reducer';

const Calendar: React.FC = () => {
  const now = new Date();

  const {i18n, t} = useTranslation();
  const userId = useSelector(getUserId);

  const userRole = useSelector(getUserRole);
  const selectedClient = useSelector(getSelectedClient);
  const [managedUser, setManagedUser]: any = useState();

  const [month, setMonth] = useState(now.getMonth());
  const [year, setYear] = useState(now.getFullYear());
  const [posts, setPosts]: [any, any] = useState({});


  let daysInMonth = new Date(year, month, 0).getDate();
  let firstDayOfMonth = new Date(year, month, 0).getDay();

  useEffect(() => {
    if (userId) {

    }
  }, [userId])

  useEffect(() => {
    if (userRole === 'manager' && selectedClient) {
      setManagedUser(selectedClient);
    } else if (userId) {
      setManagedUser(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, userId]);

  useEffect(() => {
    if (managedUser) {
      ApiClient.getScheduledMessages(managedUser)
        .then((result: any) => {
          setPosts(formatPostsToCalendar(result.data.body));
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedUser]);

  const formatPostsToCalendar = (posts: ScheduledMessage[]) => {
    const calendarPosts: any = {};
    posts.forEach(post => {
      const date = new Date(post.publicationTimestamp * 1000);
      const dateKey = date.toISOString().slice(0, 10);
      if (!calendarPosts.hasOwnProperty(dateKey)) {
        calendarPosts[dateKey] = [];
      }
      calendarPosts[dateKey].push(post);
    })
    return calendarPosts;
  }

  const renderCells = () => {
    const cells = [];
    let dayCounter = 1;

    for (let weekDay = 0; weekDay < 7; weekDay++) {
      if (weekDay < firstDayOfMonth || dayCounter > daysInMonth) {
        cells.push(renderCell('', weekDay, 'previous-month calendar-cell'));
      } else {
        cells.push(renderCell(dayCounter, weekDay, 'calendar-cell'));
        dayCounter++;
      }
    }

    const rows = [];
    rows.push(<tr className="calendar-line" key={0}>{cells}</tr>);

    while (dayCounter <= daysInMonth) {
      const rowCells = [];
      for (let weekDay = 0; weekDay < 7 && dayCounter <= daysInMonth; weekDay++) {
        rowCells.push(renderCell(dayCounter, weekDay, 'calendar-cell'));
        dayCounter++;
      }
      rows.push(<tr className="calendar-line" key={dayCounter}>{rowCells}</tr>);
    }

    return rows;
  };

  const renderPosts = (postData: ScheduledMessage[]) => {
    const postRenderer: React.ReactElement[] = [];
    postData.forEach((post, index) => {
      postRenderer.push(
        <CalendarPost key={index} post={post}
        />)
    })
    return postRenderer;
  }

  const renderCell = (day: string | number, key: string | number, styleClasses: string) => {
    const date = new Date(year, month, +day + 1).toISOString().slice(0, 10);
    return (
      <td className={styleClasses} key={key}>
        <span className="day-label fs-7">{day}</span>
        {day !== '' &&
          (<MDBBtn className="flex-fill fs-7 add-post shadow-0 py-2 px-3" color="primary" onClick={() => {
          }}>
            <MDBIcon fas icon="plus"/>
          </MDBBtn>)
        }

        {
          posts && posts[date] && renderPosts(posts[date])
        }
      </td>
    )
  }
  const getMonthName = (month: number) => {
    return new Date(year, month + 1, 0).toLocaleString(i18n.language, {month: 'long'})
  }

  const changeMonth = (value: number) => {
    setMonth((month + value) % 12);
    daysInMonth = new Date(year, month, 0).getDate();
    firstDayOfMonth = new Date(year, month, 0).getDay();
  }

  return (
    <>
      <MDBRow className="row-cols-2 py-2 m-0 shadow-1">
        <MDBCol className="d-flex align-items-center calendar-selector fs-6">
          <MDBBtn className="flex-fill" color="tertiary" onClick={() => changeMonth(-1)}>
            <MDBIcon fas icon="angle-left"/>
          </MDBBtn>
          <span className="px-3">{getMonthName(month)}</span>
          <MDBBtn className="flex-fill" color="tertiary" onClick={() => changeMonth(1)}>
            <MDBIcon fas icon="angle-right"/>
          </MDBBtn>
        </MDBCol>
        <MDBCol className="d-flex align-items-center calendar-selector fs-6">
          <MDBBtn className="flex-fill" color="tertiary" onClick={() => setYear(year - 1)}>
            <MDBIcon fas icon="angle-left"/>
          </MDBBtn>
          <span className="px-3">{year}</span>
          <MDBBtn className="flex-fill" color="tertiary" onClick={() => setYear(year + 1)}>
            <MDBIcon fas icon="angle-right"/>
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <table id="calendar" className="w-100 ">
        <thead className="fs-7">
        <tr>
          <th>{t('shared.date.monday')}</th>
          <th>{t('shared.date.tuesday')}</th>
          <th>{t('shared.date.wednesday')}</th>
          <th>{t('shared.date.thursday')}</th>
          <th>{t('shared.date.friday')}</th>
          <th>{t('shared.date.saturday')}</th>
          <th>{t('shared.date.sunday')}</th>
        </tr>
        </thead>
        <tbody>{renderCells()}</tbody>
      </table>
    </>
  );
};

export default Calendar;

interface ScheduledMessage {
  message: string;
  messageId: string;
  provider: string;
  publicationTimestamp: number;
  user: string;
  status: string;
}
import React, {useEffect, useState} from 'react';
import {getUser, updateSubscriptionAutoRenewal} from '../../shared/store/user.reducer';
import {
  MDBBtn,
  MDBCard, MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBRow, MDBSwitch
} from 'mdb-react-ui-kit';
import {useDispatch, useSelector} from 'react-redux';
import {ApiClient} from '../../shared/api_client';
import {toggleMainLoader} from '../../shared/store/app.reducer';
import {toast} from 'react-toastify';
import { useTranslation } from 'react-i18next';

const BillingSettings = () => {

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { t } = useTranslation();

  const [autoRenewal, setAutoRenewal]: [any, any] = useState(false);

  useEffect(() => {
    if (user.subscriptionAutoRenewal !== undefined) {
      setAutoRenewal(user.subscriptionAutoRenewal);
    }
  }, [user.subscriptionAutoRenewal])

  const submitChanges = () => {
    dispatch(toggleMainLoader({
      isVisible: true
    }));
    ApiClient.updateSubscription(
      user.userId, user.latestSubscriptionId,
      autoRenewal ? 'resumeSubscription' : 'cancelSubscription')
      .then(response => {

        dispatch(updateSubscriptionAutoRenewal({
          subscriptionAutoRenewal: autoRenewal
        }))
        toast.success(`Subscription auto renewal ${autoRenewal ? 'activated' : 'canceled'}`);
      })
      .catch(e => {
        setAutoRenewal(!autoRenewal);
        toast.error('An error occurred, please try again later.');
      })
      .finally(() => {
        dispatch(toggleMainLoader({
          isVisible: false
        }))
      })
  }

  const onSubRenewalChange = (e: any) => {
    setAutoRenewal(e.target.checked)
  }

  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        <MDBRow className="row-cols-sm-12">
          <MDBCardTitle className="mt-0 fs-4 mb-4">
            {t('settings.billingSettings')}
          </MDBCardTitle>
        </MDBRow>

        <MDBRow className="mb-2">
          <MDBCol className="col-6 fw-bold">
            {t('settings.accountType')}
          </MDBCol>
          <MDBCol className="col-6 text-end text-capitalize">
            {user.accountType}
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-2">
          <MDBCol className="col-6 fw-bold">
            {t('settings.subEnd')}
          </MDBCol>
          <MDBCol className="col-6 text-end">
            {
              user.subscriptionEnd * 1000 > new Date().getTime()
                ? new Date(user.subscriptionEnd * 1000).toLocaleDateString()
                : 'Expired'
            }
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-2">
          <MDBCol className="col-6 fw-bold">
            {t('settings.autoRenew')}
          </MDBCol>
          <MDBCol className="col-6 text-end">
            <MDBSwitch
              onChange={onSubRenewalChange}
              checked={autoRenewal}
              id="auto-renew-subscription"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="row-cols-sm-12 text-end mt-4">
          <MDBCol>
            <MDBBtn color="primary" size="lg" onClick={submitChanges}
                    disabled={user.subscriptionAutoRenewal === undefined || autoRenewal === user.subscriptionAutoRenewal}>
              {t('shared.update')}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

export default BillingSettings;

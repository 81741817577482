import React, { useEffect, useState } from 'react';
import {
  MDBBtn, MDBCard, MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog, MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import {
  connectSocialAccount,
  disconnectSocialAccount,
  getSocialAccounts,
  getUser
} from '../../store/user.reducer';
import { SOCIAL_ACCOUNTS } from '../../constants';
import { ApiClient } from '../../api_client';
import './social_media_settings.css';
import { toast } from 'react-toastify';
import { toggleMainLoader } from '../../store/app.reducer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SocialMediaSettings = () => {

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [deletionModalVisibility, setDeletionModalVisibility] = useState(false);
  const [socialMediaToDelete, setSocialMediaToDelete] = useState('');

  const socialMediaAccounts: any = useSelector(getSocialAccounts);
  const userData = useSelector(getUser);

  useEffect(() => {
    validateSocialMediaAuthCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.userId])

  const validateSocialMediaAuthCode = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');

    if (code && userData.userId) {
      dispatch(toggleMainLoader({
        isVisible: true
      }))
      ApiClient.storeSocialMediaAccount({
        user: userData.userId,
        provider: 'linkedin',
        code,
        redirectUri: window.location.href.split('?')[0]
      }).then(response => {
        dispatch(connectSocialAccount({
          provider: 'linkedin',
          ...response?.data
        }))
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        dispatch(toggleMainLoader({
          isVisible: false
        }))
      })
    }
  };

  const renderSocialMediaAccount = (account: any) => {
    console.log(account)
    const provider = account.provider;
    let connectionUrl = '';
    if (provider === 'linkedin') {
      const linkedinScopes = encodeURIComponent([
        'w_member_social',
        'r_basicprofile',
        'r_emailaddress',
        'r_organization_social',
        'rw_organization_admin',
        'w_organization_social'
      ].join(' '));
      connectionUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_APP_ID}&redirect_uri=${encodeURIComponent(window.location.href.split('?')[0])}&state=YOUR_STATE&scope=${linkedinScopes}`;
    }
    return (
      <MDBRow className="mb-3" key={provider}>
        <MDBCol className="col-12 d-flex align-items-center">
          <MDBIcon fab className="me-2"
                   style={{color: SOCIAL_ACCOUNTS[provider as keyof typeof SOCIAL_ACCOUNTS]?.iconColor}}
                   icon={SOCIAL_ACCOUNTS[provider as keyof typeof SOCIAL_ACCOUNTS]?.icon}
                   size="2x"/>
          <span className="provider-name text-capitalize fw-bold">{provider}</span>
          {account?.isConnected &&
            <span className="connect-social-media connected">
                <span className="flex-grow-1">
                  {t('shared.connected')}
                </span>
                <MDBBtn color="danger" outline onClick={() => {
                  setSocialMediaToDelete(provider);
                  setDeletionModalVisibility(true);
                }}>
                  <MDBIcon fas icon="times"/>
                </MDBBtn>
            </span>
          }
          {!account?.isConnected &&
            <Link to={connectionUrl}>
              <MDBBtn className="ms-3 connect-social-media" color="primary" outline rounded>
                {t('shared.connect')}
              </MDBBtn>
            </Link>
          }
        </MDBCol>
      </MDBRow>
    );
  }

  const removeSocialAccount = (provider: string) => {
    setDeletionModalVisibility(false);
    dispatch(toggleMainLoader({
      isVisible: true
    }))
    ApiClient.deleteSocialMediaAccount({
      user: userData.userId,
      provider,
      externalId: socialMediaAccounts[provider].externalId
    })
      .then(resp => {
        dispatch(disconnectSocialAccount({
          provider
        }));
        toast.success(t('settings.disconnectSocialAccountSuccess', {provider}));
      })
      .catch((err) => {
        toast.error(t('settings.disconnectSocialAccountError', {provider}));
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleMainLoader({
          isVisible: false
        }))
      })
  }

  const socialAccountDeletionModal = (provider: string) => {
    return (
      <>
        <MDBModal tabIndex="-1" show={deletionModalVisibility} setShow={setDeletionModalVisibility}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  {t('settings.disconnectSocialAccountModal.title', {provider: provider})}
                </MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={() => setDeletionModalVisibility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p>
                  {t('settings.disconnectSocialAccountModal.content', {provider: provider})}
                </p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={() => setDeletionModalVisibility(false)}>
                  {t('shared.cancel')}
                </MDBBtn>
                <MDBBtn color="danger" onClick={() => removeSocialAccount(provider)}>
                  {t('shared.disconnect')}
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    )
  }

  return (
    <MDBCard>
      <MDBCardBody>
        {socialAccountDeletionModal(socialMediaToDelete)}
        <MDBRow className="row-cols-sm-12 mb-3">
          <MDBCardTitle className="mt-0 fs-4">
            {t('settings.socialAccountSettings')}
          </MDBCardTitle>
        </MDBRow>
        {renderSocialMediaAccount(socialMediaAccounts.linkedin)}
        {renderSocialMediaAccount(socialMediaAccounts.facebook)}
        {renderSocialMediaAccount(socialMediaAccounts.instagram)}
      </MDBCardBody>
    </MDBCard>
  );
};

export default SocialMediaSettings;
import React from 'react';
import {MDBFooter} from 'mdb-react-ui-kit';
import './footer.css';

const Footer = ({darkMode}: {darkMode?: boolean}) => {
  return (
    <MDBFooter className={`font-small ${darkMode ? 'dark' : 'light'}`}>
      <div className="text-center p-3">
        <p className="m-0">© {new Date().getFullYear()} Feedler. All rights reserved</p>
      </div>
    </MDBFooter>
  );
};

export default Footer;